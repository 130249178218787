/* LIBS */
import React, { useState, useEffect, useContext } from "react";
import { toast } from "react-toastify";
import Markdown from "react-markdown";
import Button from "components/button";
import { useLocation, useNavigate } from "react-router-dom";

/* CUSTOMS */
import MultiSelectAutocomplete from "components/multiSelectAutocomplete";
import TextArea from "components/textArea";
import TextBox from "components/textBox";
import Card from "components/card";
import Heading from "components/heading";
import Title from "components/title";
import { fetchPost } from "lib/fetch";
import Loader from "components/loader";
import DropzoneComponent from "components/dropZone";
import CheckBox from "components/checkBox";
import PopupBox from "components/popupBox";
import { websocketKeyAlert } from "constants/models";
import UnsavedChangesWarning from "components/unsavedChangesWarning";

/* SERVICES */
import { UserContext } from "context/user";
import { WebSocketContext } from "context/webSocket";

/* CONSTANTS */
import {
  GetBackendPostfix,
  AuthorBackendPath,
  FieldValuesBackendPostfix,
  QueueBackendPostfix,
  CreateBackendPostfix,
  QueueBackendPath,
  UpdateBackendPostfix,
} from "constants/routing/backend";

import { EntryPostfix, ListPostfix, SubmissionQueueFrontendPath } from "constants/routing/frontend";
import { allowedImageType } from "constants/models";
import { processFileUrl } from "../fileHandle";
import { PermissionCreateTags } from "constants/permissions";

export default function AuthorSubmission() {
  const [isLoading, setIsLoading] = useState(false);

  const [entityId, setEntityId] = useState("");

  const [contentTitle, setContentTitle] = useState("");
  const [description, setDescription] = useState("");

  const [tags, setTags] = useState([]);
  const [alias, setAlias] = useState([]);
  const [fileTitleScreen, setFileTitleScreen] = useState([]);

  const [relatedLinks, setRelatedLinks] = useState([]);

  const [isGroup, setIsGroup] = useState(false);

  const [historyComment, setHistoryComment] = useState("");

  const { userContext } = useContext(UserContext);
  const { username, permissions } = userContext;

  // Function to parse query parameters
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const queueId = queryParams.get("queueId");
  const entityQueryId = queryParams.get("entityId");

  const navigate = useNavigate();

  const [modalOpen, setModalOpen] = useState(false);

  const { notifications, removeNotification } = useContext(WebSocketContext);

  useEffect(() => {
    if (entityQueryId && queueId) {
      toast.error("Cannot edit both queue and author");
      return;
    }

    if (entityQueryId) {
      contentRequest(entityQueryId);
    }

    if (queueId) {
      queueRequest(queueId);
    }
  }, []);

  useEffect(() => {
    if (Object.keys(notifications[websocketKeyAlert]).length === 0) {
      return;
    }

    const alert = notifications[websocketKeyAlert][0];


    if (alert.action !== "add" && alert.action !== "update" ) {
      return;
    }

    alert.entries.forEach((x) => {
      if (x.queueEntry.entityId === entityId) {
        setModalOpen(true);
      }
    });

    removeNotification(websocketKeyAlert);
  }, [notifications]);

  useEffect(() => {
    setEntityId(entityQueryId);
  }, [entityQueryId]);

  const markdownWidth = "md:col-span-3 col-span-6";

  const validationCheck = () => {
    var pass = true;

    if (tags.length === 0) {
      toast.error("Must select at least one tag");
      pass = false;
    }

    if (contentTitle === "") {
      toast.error("Must enter a title.");
      pass = false;
    }

    if (Object.keys(fileTitleScreen).length > 1) {
      toast.error("Cannot have more than one thumbnail.");
      pass = false;
    }

    return pass;
  };

  const queueSubmitRequest = () => {
    if (!validationCheck()) {
      return;
    }

    const isUpdate = queueId !== null;

    const params = {
      entityId: entityId,
      
      queueId: queueId,

      title: contentTitle,
      tags: tags,
      description: description,
      thumbnail: fileTitleScreen.length > 0 ? fileTitleScreen[0].ConvertToRequest() : null,
      historyComment: historyComment,
    };

    const postfix = isUpdate ? UpdateBackendPostfix : CreateBackendPostfix;

    setIsLoading(true);
    fetchPost(AuthorBackendPath + QueueBackendPostfix + postfix, params)
      .then(() => {
        toast.success(`Entry ${isUpdate ? "updated" : "created"}.`);
        if (queueId) {
          navigate(SubmissionQueueFrontendPath + EntryPostfix + queueId);
        } else {
          navigate(SubmissionQueueFrontendPath + ListPostfix);
        }
      })
      .catch((resp) => {
        toast.error(resp.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const contentRequest = (id) => {
    const body = {
      id: id,
    };

    setIsLoading(true);

    fetchPost(AuthorBackendPath + GetBackendPostfix, body)
      .then((d) => {
        setHooks(d);
      })
      .catch((resp) => {
        toast.error(resp.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const queueRequest = (id) => {
    const body = {
      id: id,
    };

    setIsLoading(true);

    fetchPost(QueueBackendPath + GetBackendPostfix, body)
      .then((e) => {
        if (!e.queueEntry.data){
          throw new Error("Queued data is empty.");
        }

        const d = JSON.parse(e.queueEntry.data);
        setHooks(d);
        
        setEntityId(e.queueEntry.entityId);
        setHistoryComment(e.queueEntry.historyComment);
      })
      .catch((resp) => {
        toast.error(resp.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const setHooks = (d) => {
    setContentTitle(d.title);
    setDescription(d.description);
    setTags(d.tags);
    setIsGroup(d.isGroup);
    setRelatedLinks(d.externalLinks);
    setAlias(d.aliases);

    processFileUrl(d.thumbnailUrl, setFileTitleScreen);
  };

  return (
    <>
      <Loader isLoading={isLoading} />
      <Title className={"col-span-6"}>{queueId || entityQueryId ? "Edit Author" : "Submit Author"}</Title>
      <UnsavedChangesWarning />

      <Card className="m-8" title={"General Information"}>
        <div className="grid grid-cols-6 gap-4 text-start">
          <TextBox
            value={contentTitle}
            id={"content-title-textBox"}
            className={"max-lg:col-span-6 md:col-span-2 w-full"}
            placeholder={"Content Title"}
            onChange={setContentTitle}
          />

          <MultiSelectAutocomplete
            className="max-lg:col-span-6 md:col-span-4"
            placeholder="Tags"
            field="tags"
            searchUri={AuthorBackendPath}
            searchPostfix={FieldValuesBackendPostfix}
            label={tags}
            isMulti={true}
            setLabel={setTags}
            isCreatable={permissions.includes(PermissionCreateTags)}
          />
          <MultiSelectAutocomplete
            className="max-lg:col-span-6 md:col-span-4"
            placeholder="Aliases"
            field="alias"
            label={alias}
            isMulti={true}
            setLabel={setAlias}
            isCreatable={true}
            hideEmpty={true}
          />
          <div className="">
            <CheckBox className="mt-3 mr-2" checked={isGroup} onChange={setIsGroup} label={"Is Group"}/>
          </div>
        </div>
      </Card>

      <Card className="m-8" title={"External Links"}>
        <div className="grid grid-cols-6 gap-4 text-start">
          <div className="max-lg:col-span-6 md:col-span-3">
            <Heading>External Links</Heading>
            <MultiSelectAutocomplete
              className="max-lg:col-span-6 md:col-span-4"
              placeholder="External Links"
              field="alias"
              label={relatedLinks}
              isMulti={true}
              setLabel={setRelatedLinks}
              isCreatable={true}
              hideEmpty={true}
            />
          </div>
          <div className="max-lg:col-span-6 md:col-span-3">
            <Heading>Preview</Heading>
            <ol>
              {relatedLinks && relatedLinks.map((x, i) => {
                return (
                  <li key={`content-link-${i}`}>
                    <a 
                      target="_blank" rel="noreferrer" 
                      href={x}>{x}
                    </a>
                  </li>
                );
              })}
            </ol>
          </div>
        </div>
      </Card>

      <Card className="m-8" title={"Avatar Upload"}>
        <div className="grid grid-cols-6 gap-4 text-start">
          <div className={markdownWidth}>
            <Heading>Avatar</Heading>
            <DropzoneComponent
              files={fileTitleScreen}
              setFiles={setFileTitleScreen}
              allowedFileTypes={allowedImageType}
              allowMultiple={false}
            />
          </div>
        </div>
      </Card>

      <Card className="m-8" title={"Description"}>
        <div className="grid grid-cols-6 gap-4 text-start">
          <div className={markdownWidth}>
            <Heading>Markdown</Heading>
            <TextArea
              noResize={true}
              wrapperClassName="w-full"
              className={"h-80"}
              value={description}
              onChange={(value) => {
                setDescription(value);
              }}
            />
          </div>
          <div className={markdownWidth}>
            <Heading>Preview</Heading>
            <div className="col-span-3 overflow-scroll h-80">
              <Markdown className="w-full">{description}</Markdown>
            </div>
          </div>
        </div>
      </Card>

      <Card className="m-8" title={"History Comment"}>
        <div className="w-full text-start">
          <TextArea
            placeholder="Comment"
            noResize={true}
            wrapperClassName="w-full"
            className={"h-20"}
            value={historyComment}
            onChange={setHistoryComment}
          />
        </div>
      </Card>

      <div className="flex justify-end mr-5">
        <Button className={"mr-3 ml-3 mb-3 mt-3 overflow-hidden rounded-b-md rounded-t-md"} onClick={queueSubmitRequest}>
          Submit
        </Button>
      </div>

      <PopupBox title={"Notice"} onClose={() => setModalOpen(false)} isOpen={modalOpen}>
        Data updated. Please refresh the page.
      </PopupBox>
    </>
  );
}
