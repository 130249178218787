import React, { useEffect } from "react";
import markdown from "../md/rules.md";
import PropTypes from "prop-types";

function RulesPage({ setRaw, setHeading }) {
  useEffect(() => {
    setRaw(markdown);
    setHeading("Rules");
  }, []);

  return <></>;
}

RulesPage.propTypes = {
  setRaw: PropTypes.func.isRequired,
  setHeading: PropTypes.func.isRequired,
};

export default RulesPage;
