export function requestFormatFilter(filters) {
  let matchFilter = {
    [filterOperatorMust]: [],
    must_not: [],
    [filterOperatorShould]: [],
  };


  filters && filters.forEach((x) => {
    if (!x){
      return;
    }
  
    matchFilter[x.operator].push({
      field: x.field,
      type: x.ruleType || "contains",
      argument: x.value,
    });
  });

  return matchFilter;
}

export const filterOperatorShould = "should";
export const filterOperatorMust = "must";
export const filterOperatorMustNot = "must_not";