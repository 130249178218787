/* LIBS */
import React from "react";
import PropTypes from "prop-types";

const colors = {
  red: "bg-red-100 text-red-700",
  orange: "bg-orange-100 text-orange-700",
  yellow: "bg-yellow-100 text-yellow-700",
  green: "bg-green-100 text-green-700",
  blue: "bg-blue-100 text-blue-700",
  indigo: "bg-indigo-100 text-indigo-700",
};
export default function Pill({ className, color, text, ...otherProps }) {
  if (!color) {
    color = "red";
  }

  const colorClasses = colors[color];

  return (
    <div
      {...otherProps}
      className={`px-4 py-2 rounded-full font-semibold text-xs flex align-center w-max uppercase ${
        colorClasses || ""
      } ${className || ""}`}
    >
      {text}
    </div>
  );
}

Pill.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string.isRequired,
  text: PropTypes.any.isRequired,
};
