/* LIBS */
import React, { useState, useContext, useEffect } from "react";
import { toast } from "react-toastify";
import Button from "components/button";
import { useLocation, useNavigate } from "react-router-dom";
import Markdown from "react-markdown";

/* CUSTOMS */
import TextArea from "components/textArea";
import Card from "components/card";
import Title from "components/title";
import { fetchPost } from "lib/fetch";
import Loader from "components/loader";
import TextBox from "components/textBox";
import CheckBox from "components/checkBox";
import UnsavedChangesWarning from "components/unsavedChangesWarning";
import Heading from "components/heading";

/* CONSTANTS */
import {
  BackendEndpoint,
  CreateBackendPostfix,
  GetBackendPostfix,
  QueueBackendPath,
  QueueBackendPostfix,
  ReviewBackendPath,
  UpdateBackendPostfix,
} from "constants/routing/backend";
import {
  DatabaseFrontendPath,
  EntryPostfix,
  ListPostfix,
  SubmissionQueueFrontendPath,
} from "constants/routing/frontend";

/* SERVICES */
import { UserContext } from "context/user";

export default function ContentReviewSubmission() {
  const [title, setTitle] = useState("");
  const [reviewText, setReviewText] = useState("");
  const [reviewResponse, setReviewResponse] = useState("");
  const [historyComment, setHistoryComment] = useState("");
  const [versionNumber, setVersionNumber] = useState("");
  const [isRecommended, setIsRecommended] = useState(false);

  const [entityId, setEntityId] = useState("");
  const [parentEntityId, setParentEntityId] = useState("");
  const [parentEntityType, setParentEntityType] = useState("");

  // Do not send
  const [reviewerUserId, setReviewerUserId] = useState("");
  const [reviewerUsername	, setReviewerUsername] = useState("");

  // Other
  const [isLoading, setIsLoading] = useState(false);
  const [dataHasChanged, setDataHasChanged] = useState(false);
  const [payloadEntity, setPayloadEntity] = useState(null);

  // Function to parse query parameters
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const queueId = queryParams.get("queueId");
  const entityQueryId = queryParams.get("entityId");

  const parentEntityIdQuery = queryParams.get("parentEntityId");
  const parentEntityTypeQuery = queryParams.get("parentEntityType");

  // User context info
  const { userContext } = useContext(UserContext);
  const { userId /*, permissions*/ } = userContext;

  const navigate = useNavigate();

  useEffect(() => {
    if (entityQueryId && queueId) {
      toast.error("Cannot edit both queue and hack");
      return;
    }

    if (entityQueryId) {
      setEntityId(entityQueryId);
    }

    if (parentEntityIdQuery){
      setParentEntityId(parentEntityIdQuery);
    }

    if (parentEntityTypeQuery){
      setParentEntityType(parentEntityTypeQuery);
    }

    if (entityQueryId) {
      contentRequest(entityQueryId);
    }

    if (queueId) {
      queueRequest(queueId);
    }
  }, []);

  const validationCheck = () => {
    var pass = true;

    return pass;
  };

  const queueSubmitRequest = () => {
    if (!validationCheck()) {
      return;
    }

    const isUpdate = queueId !== null;

    const params = {
      entityId: entityId,

      title: title,

      parentEntityId: parentEntityId,
      parentEntityType: parentEntityType,

      review: reviewText,
      reviewOwnerResponse: reviewResponse,

      version: versionNumber,
      reviewResponse: reviewResponse,
      isRecommended: isRecommended,

      historyComment: historyComment,
    };

    setIsLoading(true);

    const postfix = isUpdate ? UpdateBackendPostfix : CreateBackendPostfix;

    fetchPost(ReviewBackendPath + QueueBackendPostfix + postfix, params)
      .then(() => {
        toast.success("Entry created.");
        if (queueId) {
          navigate(SubmissionQueueFrontendPath + EntryPostfix + queueId);
        } else {
          navigate(SubmissionQueueFrontendPath + ListPostfix);
        }
      })
      .catch((resp) => {
        toast.error(resp.message);
      })
      .finally( () => {
        setIsLoading(false);
      });
  };

  const entityDataRequest = (entityType, id) => {
    setIsLoading(true);

    const params = {
      id: id
    };

    fetchPost(BackendEndpoint + "/" + entityType + GetBackendPostfix, params)
      .then((x) => {
        setPayloadEntity(x);
      })
      .catch((resp) => {
        toast.error(resp.message);
      })
      .finally( () => {
        setIsLoading(false);
      });
  };

  const queueRequest = (id) => {
    const body = {
      id: id,
    };

    fetchPost(QueueBackendPath + GetBackendPostfix, body)
      .then((e) => {
        const d = JSON.parse(e.queueEntry.data);
        setHooks(d);
        setEntityId(e.queueEntry.entityId);
        setHistoryComment(e.queueEntry.historyComment);
      })
      .catch((resp) => {
        toast.error(resp.message);
      });
  };

  const contentRequest = (id) => {
    const body = {
      id: id,
    };

    setIsLoading(true);

    fetchPost(ReviewBackendPath + GetBackendPostfix, body)
      .then((d) => {
        setHooks(d);
      })
      .catch((resp) => {
        toast.error(resp.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const setHooks = (d) => {
    setParentEntityId(d.parentEntityId);
    setParentEntityType(d.parentEntityType);

    setTitle(d.title);
    setReviewText(d.review);
    setReviewResponse(d.reviewOwnerResponse);
    setVersionNumber(d.version);
    setIsRecommended(d.isRecommended);

    setReviewerUserId(d.userId);
    setReviewerUsername(d.username);

    entityDataRequest(d.parentEntityType, d.parentEntityId);
  };

  const markdownWidth = "md:col-span-6 col-span-12";


  const isEntityOwner = payloadEntity && payloadEntity.ownerUserId === userId;
  const isReviewer = reviewerUserId === userId;

  const submissionForum = () => {
    if (!isEntityOwner && !isReviewer) {
      return(
        <Card title={"Warning"}>
          You cannot edit someone else&apos;s review or reply to content that is not your&apos;s.
        </Card>
      );
    }

    return(
      <>
        <Card title={"Review Comment"}>
          <div className="grid grid-cols-12 gap-4 text-start">
            <TextBox
              value={title}
              id={"review-headline-textBox"}
              className={"col-span-12 w-full"}
              placeholder={"Review Headline"}
              onChange={(e) => {
                setTitle(e);
                setDataHasChanged(true);
              }}
              disabled={!isReviewer}
            />

            <TextBox
              value={versionNumber}
              id={"review-version-textBox"}
              className={"max-lg:col-span-12 md:col-span-6 w-full"}
              placeholder={"Version Number"}
              onChange={(e) => {
                setVersionNumber(e);
                setDataHasChanged(true);
              }}
              disabled={!isReviewer}
            />

            <div className={"max-lg:col-span-12 md:col-span-6 w-full"}>
              <CheckBox 
                className="mt-3 mr-2" 
                checked={isRecommended} 
                label={"Is Recommended"}

                onChange={(e) => {
                  setIsRecommended(e);
                  setDataHasChanged(true);
                }}
                disabled={!isReviewer}
              />
            </div>

            <div className={markdownWidth}>
              <Heading>Markdown</Heading>
              <TextArea
                placeholder="Comment"
                noResize={true}
                wrapperClassName="w-full"
                className={"w-full h-[600px]"}
                value={reviewText}
                onChange={(e) => {
                  setReviewText(e);
                  setDataHasChanged(true);
                }}
                disabled={reviewerUserId !== userId}
              />
            </div>
            <div className={markdownWidth}>
              <Heading>Preview</Heading>
              <div className="col-span-3 overflow-scroll h-[600px]">
                <Markdown className="w-full">{reviewText}</Markdown>
              </div>
            </div>
          </div>
        </Card>

        <Card title={"Review Reply"} >

          <div className="grid grid-cols-12 gap-4 text-start">
            <div className={markdownWidth}>
              <Heading>Markdown</Heading>
              <TextArea
                placeholder="Review Reply"
                noResize={true}
                wrapperClassName="w-full"
                className={"w-full h-[600px]"}
                value={reviewResponse}
                onChange={(e) => {
                  setReviewResponse(e);
                  setDataHasChanged(true);
                }}
                disabled={!isEntityOwner}
              />
            </div>
            <div className={markdownWidth}>
              <Heading>Preview</Heading>
              <div className="col-span-3 overflow-scroll h-[600px]">
                <Markdown className="w-full">{reviewResponse}</Markdown>
              </div>
            </div>
          </div>
        </Card>

        <Card title={"History Comment"}>
          <div className="w-full text-start">
            <TextArea
              placeholder="Comment"
              noResize={true}
              wrapperClassName="w-full"
              className={"h-20"}
              value={historyComment}

              onChange={(e) => {
                setHistoryComment(e);
                setDataHasChanged(true);
              }}
            />
          </div>
        </Card>

        <div className="flex justify-end mr-5">
          <Button className={"mr-3 ml-3 mb-3 mt-3 overflow-hidden rounded-b-md rounded-t-md"} onClick={queueSubmitRequest}>
            Submit
          </Button>
        </div>
      </>
    );
  };

  return (
    <>
      <Loader isLoading={isLoading} />
      <Title className={"col-span-6"}>{queueId || entityQueryId ? "Edit Review" : "Submit Review"}</Title>
      <UnsavedChangesWarning condition={dataHasChanged} />

      <div className="grid gap-8 p-3">
        <Card title={"About"}>
          <a href={DatabaseFrontendPath + "/" + parentEntityType + EntryPostfix + parentEntityId} target="_blank" rel="noreferrer">
            <Button className={"mr-3 ml-3 mb-3 mt-3 overflow-hidden rounded-b-md rounded-t-md"} onClick={() => {}}>
              See Entity
            </Button>
          </a>
        </Card>

        {submissionForum()}
      </div>
    </>
  );
}
