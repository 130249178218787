/**
 * Object containing FDS-specific data and functions
 */

// @ts-check
import Platform from "./platform";

export default class FdsPlatform extends Platform {
  /**
   * @returns {boolean}
   */
  hasExternalHeader() {
    return (
      this.rom.length > 0x10 &&
      this.rom[0] === 0x46 &&
      this.rom[1] === 0x44 &&
      this.rom[2] === 0x53 &&
      this.rom[3] === 0x1a
    );
  }

  /**
   * @returns {number}
   */
  externalHeaderLength() {
    return 0x10; 
  }

  /**
   * Converts the rom to a normalized form.
   * @returns {object} Information about the conversion
   */
  normalize() {
    if (!this.hasExternalHeader()) {
      return { message: "", rom: null };
    }

    const romOut = this.rom.slice(this.externalHeaderLength());
    return { message: "Headered rom", rom: romOut };
  }
}
