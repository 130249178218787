/* LIBS*/
import React, { useRef } from "react";
import PropTypes from "prop-types";
import DatePicker from "react-datepicker";

/* CUSTOM */
import TextBox from "components/textBox";
import { DateUTCToLocal, StripTime } from "lib/time";

/** STYLING */
import "react-datepicker/dist/react-datepicker.css";

const CustomInput = React.forwardRef(({ id2, value, onClick, className, placeholder2 }, ref) => {
  return (
    <TextBox
      ref={ref}
      id={id2}
      onClick={onClick}
      placeholder={`${placeholder2 || "Select a date..."}`}
      value={value}
      onChange={(e) => {}}
      className={`${className || ""}`}
    />
  );
});

export default function Calendar({ startDate, endDate, onChange, className, displayUTC, placeholder, selectsRange, id, ...otherProps }) {
  const start = displayUTC ? DateUTCToLocal(startDate) : startDate;
  const end = displayUTC ? DateUTCToLocal(endDate) : endDate;
  const maxDate = displayUTC ? DateUTCToLocal(new Date()) : new Date();

  const calendarTextInput = useRef(null);

  return (
    <DatePicker
      key={`datepicker-${Date.parse(start)}-${Date.parse(end)}`}
      customInput={<CustomInput ref={calendarTextInput} id2={id} placeholder2={placeholder} className={className} onChange2={onChange} />}
      wrapperClassName={className}
      selected={start}
      startDate={start}
      endDate={end}
      onChange={(dates) => {
        if (!selectsRange) {
          onChange(StripTime(dates));
          return;
        }

        if (dates instanceof Array) {
          let [start, end] = dates;
          onChange([StripTime(start), StripTime(end)]);
        }

        if (dates instanceof Date) {
          onChange([StripTime(dates), null]);
        }
      }}
      timeFormat=""
      dateFormat="yyyy-MM-dd"
      minDate={new Date(0)}
      maxDate={maxDate}
      showTimeSelect={false}
      isClearable={false}
      selectsRange={selectsRange}
      {...otherProps}
    />
  );
}

Calendar.displayName = "Calendar";
CustomInput.displayName = "CustomInput";

CustomInput.propTypes = {
  id2: PropTypes.string.isRequired,
  value: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
  placeholder2: PropTypes.string,
};

Calendar.propTypes = {
  id: PropTypes.string,
  startDate: PropTypes.instanceOf(Date),
  endDate: PropTypes.instanceOf(Date),
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  displayUTC: PropTypes.bool,
  selectsRange: PropTypes.bool,
};
