import React from "react";
import Button from "../button";
import PropTypes from "prop-types";

export default function ButtonGroup({ properties }) {
  return (
    <div
      className="inline-flex shadow-md hover:shadow-lg focus:shadow-lg mr-3 ml-3 mb-3 mt-3 overflow-hidden rounded-b-md rounded-t-md"
      role="group"
    >
      {properties.map(({ onClick, text, disabled, title }) => {
        const uniqueId = Math.random().toString(16).slice(2);

        return (
          <Button
            key={uniqueId}
            onClick={onClick}
            disabled={disabled}
            title={title}
          >
            {text}
          </Button>
        );
      })}
    </div>
  );
}

ButtonGroup.propTypes = {
  properties: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.any.isRequired,
      onClick: PropTypes.func.isRequired,
      disabled: PropTypes.bool.isRequired,
      title: PropTypes.string,
    })
  ).isRequired,
};
