import React from "react";
import Moment from "react-moment";
import { EpochSecondsToEpochMilliseconds } from "lib/time";
import PropTypes from "prop-types";
import { Tooltip } from "react-tooltip";
import { RandomId } from "lib/randomId";

export default function Time({ time, className, fromNow, customFormat }) {
  var timeFormat = customFormat || "YYYY MMM DD";

  var toolTipId = `tooltip-${time}-${RandomId()}`;

  const normalizedTime = EpochSecondsToEpochMilliseconds(time);

  return (

    <div className={className}>
      {time !== 0 ? (
        <span data-tooltip-id={toolTipId}>
          <Moment
            date={normalizedTime}
            format={!fromNow ? timeFormat : null}
            utc={true}
            fromNow={fromNow}
          />
        </span>
      ) : (
        "N/A"
      )}
      {fromNow ? <Tooltip id={toolTipId} className="select-none">
        <Moment format={timeFormat} date={normalizedTime} utc={true} />
      </Tooltip>: null}
    </div>
  );
}

Time.propTypes = {
  time: PropTypes.number.isRequired,
  className: PropTypes.string,
  fromNow: PropTypes.bool,
  customFormat: PropTypes.string
};
