import { filterOperatorMust, filterOperatorShould } from "lib/elastic";

export const websocketKeyAlert = "alert";
export const websocketKeyMessage = "message";

export const defaultWebsocketObject = {
  [websocketKeyAlert]: [],
  [websocketKeyMessage]: [],
};

export const pageSize = 15;
export const autocompleteWindowMax = 15;

export const OperatorOptions = [
  { value: filterOperatorMust, label: "Is" },
  { value: filterOperatorShould, label: "Should" },
  { value: "must_not", label: "Is not" },
];

export const queryColumnsKey = "columns";
export const queryFiltersKey = "filters";
export const queryStartDateKey = "startTime";
export const queryEndDateKey = "endTime";
export const queryTimeFilterOptionKey = "timeFilterOption";
export const queryIdKey = "id";

export const allowedImageType = [".png"];
export const allowedArchiveType = [".zip", ".rar", ".7z"];
export const allowedReadmeType = [".md", ".txt"];
export const allowedRomType = [
  ".nes", ".fds",
  ".sfc", ".smc", 
  "sms", 
  ".bin", ".cue", 
  ".z64", ".v64", ".n64", 
  ".iso", 
  ".sms", 
  ".gb", 
  ".md",
];

export const newId = "NEW";

export const queueActionColorTable = {
  insert: "green",
  delete: "red",
  update: "yellow",
};

export const blockListItemType = ["IP Address", "User Agent"];