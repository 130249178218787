/* LIBS */
import React, { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";

/* CUSTOMS */
import Paginator from "components/paginator";
import { pageSize } from "constants/models";
import { fetchPost } from "lib/fetch";
import Title from "components/title";
import Loader from "components/loader";
import { FriendlyTable } from "components/friendlyTable";
import { FriendlyFilterCard } from "components/friendlyFilterCard";

/* CONSTANTS */
import {
  GameBackendPath,
  FriendlyBackendPostfix,
  SearchBackendPostfix,
  MetadataBackendPostfix,
  FieldValuesBackendPostfix,
} from "constants/routing/backend";
import { requestFormatFilter } from "lib/elastic";
import { DatabaseFrontendPath, EntryPostfix, GamesFrontendPath } from "constants/routing/frontend";
import InvisibleScroll from "components/invisibleScroll";

export default function GameGridView() {
  const [payload, setPayload] = useState(null);
  const [metadata, setMetadata] = useState(null);
  const [sortField] = useState("title");
  const [sortDirection] = useState("asc");
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [filters, setFilters] = useState([]);

  const [queryString, setQueryString] = useState("");

  const columns = [
    {
      label: "Platform",
      field: "platform",
      width: "",
      dataType: "write",
    },
    {
      label: "Publisher",
      field: "publisher",
      width: "",
      dataType: "write",
    },
    {
      label: "Tags",
      field: "tags",
      width: "col-span-12",
      dataType: "pills",
    },
  ];

  useEffect(() => {
    setIsLoading(true);
    fetchPost(GameBackendPath + FriendlyBackendPostfix + MetadataBackendPostfix, null)
      .then((resp) => {
        setMetadata(resp);
        setFilters(Array(Object.keys(resp.filters).length));
      })
      .catch((resp) => {
        toast.error(resp.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    setPage(0);
  }, [sortField, sortDirection, filters]);

  useEffect(() => {
    hackRequest();
  }, [page, sortField, sortDirection, filters]);

  const hackRequest = () => {
    setIsLoading(true);

    const body = {
      page: page,
      sortField: sortField,
      sortDirection: sortDirection,
      filters: requestFormatFilter(filters),
      queryString: queryString,
    };

    fetchPost(GameBackendPath + FriendlyBackendPostfix + SearchBackendPostfix, body)
      .then((resp) => {
        setPayload(resp);
      })
      .catch((resp) => {
        toast.error(resp.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const gameMultiSelectRef = useRef(null);

  const paginationWrapper = (autoScroll, allowInputChange) => {
    if (!payload) {
      return;
    }

    const onChange = () => {
      if (autoScroll) {
        gameMultiSelectRef.current.jumpToSection();
      }
    };

    return (
      <Paginator
        page={page}
        setPage={setPage}
        currentPageSize={payload.entries && payload.entries.length}
        pageWindowSize={pageSize}
        totalEntries={payload && payload.total}
        allowInputChange={allowInputChange}
        onChange={onChange}
      />
    );
  };

  return (
    <>
      <Title>Games</Title>
      <Loader isLoading={isLoading} />

      <div className="lg:grid lg:grid-cols-12 gap-3">
        <div className="col-span-12">
          <FriendlyFilterCard
            filters={filters}
            setFilters={setFilters}
            setQueryString={setQueryString}
            searchUri={GameBackendPath}
            fieldsUri={GameBackendPath + FieldValuesBackendPostfix}
            onClick={hackRequest}
            metadata={metadata}
          />
        </div>
        
        <InvisibleScroll ref={gameMultiSelectRef} />

        <div className="col-span-12">
          <div className="flex items-center justify-end">{paginationWrapper(false, true)}</div>
          <FriendlyTable
            data={payload && payload.entries}
            titleKey="title"
            thumbnailKey="thumbnailUrl.url"
            preambleKey="description"
            gotoIdPath={DatabaseFrontendPath + GamesFrontendPath + EntryPostfix}
            columns={columns}
          />
          <div className="flex items-center justify-end">{paginationWrapper(true, false)}</div>
        </div>
      </div>
    </>
  );
}
