/* LIBS */
import { toast } from "react-toastify";

/* CUSTOMS */
import { FileInformation } from "components/dropZone";
import { downloadFileToArrayBuffer, extractBucketAndKey } from "lib/downloadUrl";

export const processFileUrl = (url, setFileFunction, setIsLoading) => {
  if (!url) {
    return;
  }

  const { key, keyPostfix } = extractBucketAndKey(url.url);

  if (url.url.startsWith("s3://")) {
    setFileFunction([new FileInformation(key, new Uint8Array(0), keyPostfix, false)]);
    return;
  }

  if (setIsLoading){
    setIsLoading(true);
  }

  downloadFileToArrayBuffer(url.url)
    .then((bytes) => {
      setFileFunction([new FileInformation(key, bytes, keyPostfix, false)]);
    })
    .catch((resp) => {
      toast.error(resp.message);
      setFileFunction([new FileInformation(key, null, keyPostfix, false)]);
    })
    .finally(() => {
      if (setIsLoading){
        setIsLoading(false);
      }
    });
};

export const processFilesUrl = (url, setFileFunction, setIsLoading) => {
  if (!url) {
    return;
  }

  if (setIsLoading){
    setIsLoading(true);
  }

  const downloadPromises = url && url.map((x) => {
    const { key, keyPostfix } = extractBucketAndKey(x.url);

    if (x.url.startsWith("s3://")) {
      return new FileInformation(key, new Uint8Array(0), keyPostfix, false);
    }

    return downloadFileToArrayBuffer(x.url)
      .then((bytes) => {
        return new FileInformation(key, bytes, keyPostfix, false);
      })
      .catch((error) => {
        toast.error(error.message);
        return new FileInformation(key, null, keyPostfix, false);
      });
  });

  // Use Promise.all to wait for all download promises to complete
  downloadPromises &&
    Promise.all(downloadPromises)
      .then((screenshots) => {
        const validScreenshots = screenshots.filter((s) => s !== null);
        setFileFunction(validScreenshots);
      })
      .catch((error) => {
        toast.error(error.message);
      })
      .finally( () => {
        if (setIsLoading){
          setIsLoading(false);
        }
      });
};
