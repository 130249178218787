/* LIBS */
import React, { useEffect } from "react";
import PopupBox from "components/popupBox";
import PropTypes from "prop-types";

//import RomPatcher from "rom-patcher";

export default function PatchModal({ modalOpen, setModalOpen }) {
  const onClose = () => {
    setModalOpen(false);
  };

  useEffect(() => {
    if (!modalOpen) {
      return;
    }
  }, [modalOpen]);

  return (
    <>
      <PopupBox title={"Patch"} onClose={onClose} isOpen={modalOpen}></PopupBox>
    </>
  );
}

PatchModal.propTypes = {
  modalOpen: PropTypes.bool,
  setModalOpen: PropTypes.func,
};
