import React, { createContext, useState, useEffect } from "react";
import PropTypes from "prop-types";

// Create the context
export const ThemeContext = createContext();

const prefersDarkScheme = window.matchMedia("(prefers-color-scheme: dark)").matches;

// Create a provider component
export const ThemeProvider = ({ children }) => {


  const [theme, setTheme] = useState(() => {
    return localStorage.getItem("theme") || (prefersDarkScheme ? "dark": "light");
  });

  useEffect(() => {
    document.documentElement.className = theme;
    localStorage.setItem("theme", theme);
  }, [theme]);

  const toggleTheme = () => {
    setTheme((prevTheme) => (prevTheme === "light" ? "dark" : "light"));
  };

  return <ThemeContext.Provider value={{ theme, toggleTheme }}>{children}</ThemeContext.Provider>;
};

// PropTypes for ThemeProvider
ThemeProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
