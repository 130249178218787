import PropTypes from "prop-types";
import React from "react";

export default function RadioButtons(props) {
  let {
    properties,
    keyPrefix,
    checkedVariable,
    onChange,
    className,
    disabled,
  } = props;
  return (
    <>
      {properties.map((x, i) => {
        return (
          <div
            key={`${keyPrefix}-${i}`}
            className={`ml-3 select-none ${className || ""}`}
            onClick={() => {
              if (disabled) {
                return;
              }
              onChange(x.value);
            }}
          >
            <input
              className="mr-1"
              type="radio"
              value={x.value}
              checked={checkedVariable === x.value}
              disabled={disabled}
              onChange={() => {
                if (disabled) {
                  return;
                }
                onChange(x.value);
              }}
            />
            {x.label}
          </div>
        );
      })}
    </>
  );
}

RadioButtons.propTypes = {
  keyPrefix: PropTypes.string.isRequired,
  properties: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
  checkedVariable: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
};
