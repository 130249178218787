/* LIBS */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

/* CUSTOMS */
import Paginator from "components/paginator";
import { Table } from "components/table";
import Heading from "components/heading";
import { AuthorFrontendPath, DatabaseFrontendPath } from "constants/routing/frontend";

export default function Credit({ payload }) {
  if (!payload) {
    return;
  }

  const rightText = "px-6 text-left break-words";
  const headerColClass = "px-6 py-3 text-left tracking-wider";

  const [page, setPage] = useState(0);

  const size = 5;

  const aaa = payload.slice(size * page, size * (page+1));
  
  const paginationElement = (allowInputChange) => {
    if (!payload) {
      return;
    }

    if (payload.length <= size){
      return;
    }

    return (
      <Paginator
        page={page}
        setPage={setPage}
        currentPageSize={aaa.length}
        pageWindowSize={size}
        totalEntries={payload.length}
        enableAutoScroll={false}
        allowInputChange={allowInputChange}
      />
    );
  };

  return (
    <>
      <Heading>Credits</Heading>
      <div className="flex items-center justify-end">
        {paginationElement(false)}
      </div>
      <Table>
        <thead>
          <tr>
            <td className={`w-30 ${headerColClass}`}>Contributor</td>
            <td className={`w-30 ${headerColClass}`}>Contribution Type</td>
            <td className={`w-30 ${headerColClass}`}>Description</td>
          </tr>
        </thead>
        <tbody>
          {aaa.map((c, i) => {
            return (
              <tr className="ml-3" key={`credits-${i}`}>
                <td className={rightText}>
                  <Link to={DatabaseFrontendPath + AuthorFrontendPath + "/entry/" + c.creditId}>
                    {c.creditName}
                  </Link>
                </td>
                <td className={rightText}>{c.type}</td>
                <td className={rightText}>{c.description}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </>
  );
}

Credit.propTypes = {
  payload: PropTypes.array,
};
