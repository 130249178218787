import React from "react";
import PropTypes from "prop-types";

const Checkbox = ({ id, className, checked, onChange, label, disabled }) => {
  let checkBox = (
    <input
      id={id}
      className={`h-5 w-5 accent-primaryBlue ${className || ""} disabled:opacity-20`}
      type="checkbox"
      checked={checked}
      onChange={(e) => {
        if (disabled) {
          return;
        }

        onChange(e.target.checked);
      }}
      disabled={disabled}
    />
  );

  return (
    <>
      {checkBox}
      <span
        onClick={() => {
          if (disabled) {
            return;
          }

          onChange(!checked);
        }}
        className={`select-none ml-1 ${disabled && "opacity-20"}`}
      >
        {label ?? label}
      </span>
    </>
  );
};

export default Checkbox;

Checkbox.propTypes = {
  className: PropTypes.string,
  checked: PropTypes.bool,
  id: PropTypes.any,
  onChange: PropTypes.func,
  label: PropTypes.string,
  disabled: PropTypes.bool,
};
