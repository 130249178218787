import axios from "axios";

export function fetchGet(url, params) {
  return fetch("GET", url, null, params);
}

export function fetchPost(url, body, params, signal) {
  return fetch("POST", url, body, params, signal);
}

export function fetchDelete(url, body, params) {
  return fetch("DELETE", url, body, params);
}

export function fetchPut(url, body, params) {
  return fetch("PUT", url, body, params);
}

function fetch(method, url, body, params, signal) {
  return new Promise((resolve, reject) => {
    if ((method === "GET" || method === "HEAD") && body) {
      reject({
        data: null,
        message: "GETs with payloads not supported by axios",
      });
      return;
    }

    axios({
      method: method,
      data: JSON.stringify(body),
      url: url,
      params: params,
      withCredentials: true,
      secure: true,
      signal: signal,
    })
      .then((resp) => {
        resolve(formatOkResponse(resp));
        return;
      })
      .catch((resp) => {
        reject(formatBadResponse(resp));
        return;
      });
  });
}

function formatOkResponse(resp) {
  let ret = resp.data ? resp.data : resp.message;

  if (!ret) {
    ret = "";
  }
  return ret;
}

function formatBadResponse(resp) {
  let ret = resp.response ? resp.response.data : resp;

  if (ret && ret.message) {
    ret = ret.message;
  }

  if (ret) {
    ret = { message: capitalizeFirstLetter(ret) };
  }

  if (!ret){
    if (resp.response) {
      switch (resp.response.status){
      case 429:
        ret = { message: "Too many requests" };
        break;
      case 404:
        ret = { message: "Page not found" };
        break;
      case 500:
        ret = { message: "Internal server error" };
        break;
      default:
      }
    }
  }

  return ret;
}

function capitalizeFirstLetter(string) {
  if (typeof string !== "string"){
    return string;
  }


  if (!string || string.length === 0){
    return string;
  }

  return string.charAt(0).toUpperCase() + string.slice(1);
}
