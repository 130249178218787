import React, { useState } from "react";
import PropTypes from "prop-types";

const Details = ({
  children,
  className,
  ...otherProps
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };
  

  return (
    <div className={`p-4 ${className}`} {...otherProps}>
      <details open={isOpen} onToggle={handleToggle} className="rounded">
        <summary className="cursor-pointer font-bold text-lg p-4">
          {isOpen ? "Collapse" : "Expand"} Content
        </summary>
        <div className="">
          {children}
        </div>
      </details>
    </div>
  );
};

Details.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any.isRequired,
  headingId: PropTypes.any,
};
  

export default Details;