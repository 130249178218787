/**
 * Object containing snes-specific data and functions
 */

// @ts-check
import Platform from "./platform";

const copyHeaderSize = 0x200;

export default class SnesPlatform extends Platform {
  /**
   * @returns {boolean}
   */
  hasExternalHeader() {
    return this.rom.length % 1024 === copyHeaderSize;
  }

  /**
   * @returns {number}
   */
  externalHeaderLength() {
    return copyHeaderSize;
  }

  /**
   * Converts the rom to a normalized form.
   * @returns {object} Information about the conversion
   */
  normalize() {
    if (!this.hasExternalHeader()) {
      return { message: "", rom: null };
    }

    this.rom = this.rom.slice(copyHeaderSize);
    return { message: "Has 0x200 header", rom: null };
  }
}
