
import React from "react";

import PopupBox from "components/popupBox";
import Markdown from "react-markdown";
import PropTypes from "prop-types";

export default function  AprilFoolsPopupBox ({modalOpen, setModalOpen}) {
  function isWindows() {
    return navigator.platform.indexOf("Win") > -1;
  }

  const osMessage = isWindows()
    ? "You're still using a Microsoft OS? Get with the program, switch to Linux or BSD."
    : "Come on, use a real Operating System like Windows, stop being different.";

  const message = [
    "AntoineWG was here!",
    "Santa comes when it snows before the new year.",
    "Midnight vampires flee before ZSNES and its control of garlic toast.",
    "ZSNES has detected that you did not donate today. You will now experience our wrath.",
    "Your SNES does not seem to be plugged into your television properly.",
    "In ~81 days a solar powered ZSNES will be at its peak!",
    "We are now reporting your gaming activities to Nintendo's central servers. Please wait a moment.",
    "Are you hearing any voices in your head right now?",
    "It's not too late invest in ZSNES today!",
    "Did you know if you buy pagefault beer you will make him happy?",
    "Don't you feel terrible knowing you use ZSNES and haven't donated enough towards it?",
    "Why are you playing games when you should be spending quality time with your family?",
    "It's best to play SNES games while wearing boxing gloves.",
    "Do you think using ZSNES increases your desire to support development?",
    "Thank you for playing.\n\nPresented by\n\nthe ZSNES team!",
    "What did you load ZSNES for? Try another SNES emulator.",
    "Please scan your computer for viruses!",
    "We think your computer hates you! Be afraid!",
    "Did you know that a large percentage of ZSNES was created by a fish?",
    "Winners don't use drugs.",
    osMessage,
  ];

  function getRandomMessage(arr) {
    const randomIndex = Math.floor(Math.random() * arr.length);
    return arr[randomIndex];
  }

  return (
    <PopupBox title={"Welcome to ZSNES"} onClose={() => setModalOpen(false)} isOpen={modalOpen}>
      {<Markdown>{getRandomMessage(message)}</Markdown>}
    </PopupBox>
  );
}

AprilFoolsPopupBox.propTypes = {
  modalOpen: PropTypes.bool.isRequired,
  setModalOpen: PropTypes.func.isRequired,
};