/** LIBS */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { fetchPost } from "lib/fetch";

/** CUSTOMS */
import MultiSelect from "components/multiSelect";
import Button from "components/button";
import { ToLabelValueFromMap, ToLabelValueFromSource, ToLabelValue } from "lib/strings";
import { toast } from "react-toastify";
import { filterOperatorMust, filterOperatorShould, requestFormatFilter } from "lib/elastic";

/* ICONS */
import { FaXmark } from "react-icons/fa6";

/* CONSTANTS */
import { FieldValuesBackendPostfix } from "constants/routing/backend";
import { OperatorOptions } from "constants/models";
import { autocompleteWindowMax } from "constants/models";

export default function FilterItem({ filterItem, action, index, filters, setFilters, searchUri }) {
  const [operator, setOperator] = useState(filterItem && filterItem.operator ? filterItem.operator : filterOperatorMust);
  const [value, setValue] = useState(filterItem ? filterItem.value : "");
  const [fieldValues, setFieldValues] = useState(null);
  const [firstLoad, setFirstLoad] = useState(true);

  const [autocompleteController, setController] = useState(null);

  const [valueAutocomplete, setValueAutocomplete] = useState("");

  useEffect(() => {
    if (!filterItem.field) {
      return;
    }

    if (firstLoad){
      setFirstLoad(false);
      return;
    }

    fieldGet();
  }, [valueAutocomplete]);

  useEffect(() => {
    setFieldValues(null);
  }, [filters]);

  useEffect(() => {
    if (filters.length === 0 || index === null) {
      return;
    }

    const temp = [...filters];
    temp[index].operator = operator;
    temp[index].value = value;

    setFilters(temp);
  }, [operator, value]);

  useEffect(() => {

    setFieldValues(null);
  }, [operator]);

  const deleteItem = () => {
    const temp = [...filters];
    temp.splice(index, 1);
    setFilters(temp);
  };

  const addItem = () => {
    const temp = {
      field: filterItem.field,
      operator: operator,
      value: value,
    };
    setFilters([...filters, temp]);
    setOperator("");
    setValue("");
  };

  if (operator && value && action === "Add") {
    addItem();
  }

  const fieldGet = () => {
    const params = {
      field: filterItem.field,
      value: valueAutocomplete,
    };

    /*
    The should operator lets you display content that isn't currently being displayed, hence 
    the dropdown shouldn't be limited by the filter if this operator is selected
    */
    if (operator !== filterOperatorShould){
      params.filters = requestFormatFilter(filters);
    }

    if (autocompleteController) {
      autocompleteController.abort();
      setController(null);
    }

    const controller2 = new AbortController();
    setController(controller2);

    fetchPost(searchUri + FieldValuesBackendPostfix, params, controller2.signal)
      .then((resp) => {
        setFieldValues(resp);
      })
      .catch((e) => {
        toast.error(e.message);
      });
  };

  var actionButton;

  switch (action) {
  case "Add":
    break;
  case "Delete":
    actionButton = (
      <Button onClick={deleteItem} secondary={true} className="bg-primaryBlue m-4 hover:bg-primaryBlueHover rounded-lg">
        <FaXmark />
      </Button>
    );
    break;
  default:
    throw new Error(`Invalid action: ${action}`);
  }

  return (
    <div className="grid grid-cols-6">
      <MultiSelect
        className="m-1 col-span-2"
        placeholder="Operator"
        isMulti={false}
        onSelect={(e) => {
          setOperator(e.value);
        }}
        options={OperatorOptions}
        value={ToLabelValueFromSource(operator, OperatorOptions)}
        onInputChange={() => {}}
      />
      <div className="col-span-3">
        <MultiSelect
          placeholder="Value"
          isMulti={false}
          className="m-1 text-green-500"
          onMenuOpen={() => {
            if (fieldValues) {
              return;
            }

            fieldGet();
          }}
          value={ToLabelValue(value)}
          onSelect={(e) => {
            setValue(e.label);
          }}
          onCreate={(e) => {
            setValue(e.label);
          }}
          onInputChange={(value, meta) => {
            if (!setValueAutocomplete) {
              return;
            }

            if (value.length > valueAutocomplete.length && Object.keys(fieldValues).length < autocompleteWindowMax) {
              return;
            }

            if (value === "" && (meta.action === "set-value" || meta.action === "menu-close" || meta.action === "input-blur")) {
              return;
            }
            setValueAutocomplete(value);
          }}
          options={ToLabelValueFromMap(fieldValues)}
        />
      </div>
      <div className="col-span-1 flex items-center">{actionButton}</div>
    </div>
  );
}

FilterItem.propTypes = {
  filterItem: PropTypes.object,
  action: PropTypes.string,
  index: PropTypes.number,
  searchUri: PropTypes.string.isRequired,

  filters: PropTypes.arrayOf(
    PropTypes.shape({
      operator: PropTypes.string,
      field: PropTypes.string.isRequired,
      value: PropTypes.string,
      disabled: PropTypes.bool,
    })
  ).isRequired,
  setFilters: PropTypes.func,
};
