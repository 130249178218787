import React from "react";
import GoCaptcha from "go-captcha-react";
import PropTypes from "prop-types";

/*
https://github.com/wenlng/go-captcha-example
*/

const CaptchaComponent = ({ data, closeEvent, refreshEvent, confirmEvent  }) => {
  return (
    <GoCaptcha.Slide
      config={{
        //width: 300,
        //height: 240,
        showTheme: false,
        verticalPadding: 5,
        horizontalPadding: 5,
        title: "Please drag the slider to complete the puzzle",
      }}
      data={data}
      events={{
        close: closeEvent,
        refresh: refreshEvent,
        confirm: confirmEvent,
      }}
    />
  );
};

CaptchaComponent.propTypes = {
  data: PropTypes.any,
  closeEvent: PropTypes.func,
  refreshEvent: PropTypes.func,
  confirmEvent: PropTypes.func,
};
  
export default CaptchaComponent;