/** LIBS */
import React from "react";
import { Route, Routes } from "react-router-dom";

/* CUSTOMS */
import ButtonLinksTabbed from "components/buttonLinksTabbed";
import SeoHeader from "components/seoHeader";
import UserEntry from "./user/entry";
import BlockListGridView from "./blocklist/grid";
import ActiveSessions from "./sessions";
import { UserGridView } from "./user";

/* CONSTANTS */
import {
  AdminFrontendPath,
  BlockListFrontendPath,
  UserFrontendPath,
} from "constants/routing/frontend";

export default function DatabaseListRoutes() {
  const tabbedLinks = [
    {
      text: "Users",
      path: AdminFrontendPath + UserFrontendPath,
      pathPart: UserFrontendPath,
      element: <UserGridView />,
    },
    {
      text: "Sessions",
      path: AdminFrontendPath + "/sessions",
      pathPart: "/sessions",
      element: <ActiveSessions />,
    },
    {
      text: "Block List",
      path: AdminFrontendPath + BlockListFrontendPath,
      pathPart: BlockListFrontendPath,
      element: <BlockListGridView />,
    },
  ];

  const entryId = "/entry/:id";

  const hiddenLinks = [
    {
      pathPart: UserFrontendPath + entryId,
      element: <UserEntry />,
    },
  ];
  return (
    <>
      <SeoHeader 
        pageTitle={"Admin"} 
      />
      <ButtonLinksTabbed id="admin" links={tabbedLinks} />

      <Routes>
        {tabbedLinks.map(({ pathPart, element }, i) => {
          return (
            <Route
              key={`blocklistRoute1-${i}`}
              path={pathPart}
              exact
              element={element}
            />
          );
        })}

        {hiddenLinks.map(({ pathPart, element }, i) => {
          return (
            <Route
              key={`searchRoute-${i}`}
              path={pathPart}
              exact
              element={element}
            />
          );
        })}
      </Routes>
    </>
  );
}
