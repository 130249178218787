import React from "react";
import PropTypes from "prop-types";
import { NavLink, useLocation } from "react-router-dom";

export default function ButtonLinksTabbed({ links, id }) {
  const location = useLocation();

  return (
    <div className="flex justify-center mb-5">
      <div className="flex items-center justify-evenly w-full md:w-3/4 truncate rounded-b-lg shadow-lg">
        {links.map(({ text, path, title }, i) => {
          return (
            <NavLink
              exact={"true"}
              className={`truncate p-2 bg-primaryBlue hover:opacity-40 w-full h-full text-white hover:text-white text-sm 
            ${location.pathname === path && "opacity-50 text-accent cursor-default"} select-none`}
              key={`${id}-${i}`}
              to={path}
              title={title || text}
            >
              {text}
            </NavLink>
          );
        })}  </div>
    </div>
  );
}

ButtonLinksTabbed.propTypes = {
  id: PropTypes.string.isRequired,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.any.isRequired,
      path: PropTypes.string.isRequired,
    })
  ).isRequired,
};
