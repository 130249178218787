/* LIBS */
import React from "react";
import PropTypes from "prop-types";

/* CUSTOMS */
import Heading from "components/heading";
import Pill from "components/pill";

export default function Tags({ payload }) {
  if (!payload.tags) {
    return;
  }

  return (
    <div className="text-left">
      <Heading>Tags</Heading>
      <div className="flex flex-wrap">
        {payload.tags &&
              payload.tags.map((x, i) => {
                return <Pill key={`bullet-${x.id}-${i}`} color={"blue"} className={"m-1"} text={x} />;
              })}
      </div>
    </div>
  );
}

Tags.propTypes = {
  payload: PropTypes.object.isRequired,
};
