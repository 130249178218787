/* LIBS */
import React, { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

/* CUSTOMS */
import Paginator from "components/paginator";
import { pageSize } from "constants/models";
import { fetchPost } from "lib/fetch";
import Title from "components/title";
import Loader from "components/loader";
import { FriendlyTable } from "components/friendlyTable";
import { FriendlyFilterCard } from "components/friendlyFilterCard";

/* CONSTANTS */
import {
  ContentBackendPath,
  SearchBackendPostfix,
  FriendlyBackendPostfix,
  MetadataBackendPostfix,
  FieldValuesBackendPostfix,
} from "constants/routing/backend";
import { filterOperatorMust, requestFormatFilter } from "lib/elastic";
import { AuthorFrontendPath, ContentFrontendPath, DatabaseFrontendPath, EntryPostfix, GamesFrontendPath } from "constants/routing/frontend";
import InvisibleScroll from "components/invisibleScroll";

export default function TranslationGridView() {
  const [payload, setPayload] = useState(null);
  const [metadata, setMetadata] = useState(null);
  const [sortField] = useState("releaseDate");
  const [sortDirection] = useState("desc");
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [filters, setFilters] = useState([]);

  const [queryString, setQueryString] = useState("");

  const columns = [
    {
      label: "Original Game",
      field: "games",
      width: "",
      dataType: "custom",
      custom: (data) => {
        return (
          data &&
          data.map((x, i) => {
            const to = DatabaseFrontendPath + GamesFrontendPath + EntryPostfix;
            return (
              <span key={i}>
                <Link className="text-sm" to={to + x.gameId}>{x.title}</Link>
                {i < data.length - 1 && ", "}
              </span>
            );
          })
        );
      },
    },
    {
      label: "Platform",
      field: "platform",
      width: "",
      dataType: "write",
    },
    {
      label: "Released By",
      field: "authors",
      width: "",
      dataType: "custom",
      custom: (data) => {
        return (
          data &&
          data.map((x, i) => {
            const to = DatabaseFrontendPath + AuthorFrontendPath + EntryPostfix;
            return (
              <span key={i}>
                <Link className="text-sm" to={to + x.authorId}>{x.authorName}</Link>
                {i < data.length - 1 && ", "}
              </span>
            );
          })
        );
      },
    },
    {
      label: "Downloads",
      field: "downloadCount",
      width: "",
      dataType: "write",
    },
    {
      label: "Tags",
      field: "tags",
      width: "col-span-12",
      dataType: "pills",
    },
  ];

  useEffect(() => {
    setIsLoading(true);
    fetchPost(ContentBackendPath + FriendlyBackendPostfix + MetadataBackendPostfix, null)
      .then((resp) => {
        setMetadata(resp);

        const temp = Array(Object.keys(resp.filters).length).fill(null);
        temp.push({
          field: "tags",
          operator: filterOperatorMust,
          value: "Translation",
        });

        setFilters(temp);
      })
      .catch((resp) => {
        toast.error(resp.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    setPage(0);
  }, [sortField, sortDirection, filters]);

  useEffect(() => {
    hackRequest();
  }, [page, sortField, sortDirection, filters]);

  const hackRequest = () => {
    if (!metadata) {
      return;
    }

    setIsLoading(true);

    const body = {
      page: page,
      sortField: sortField,
      sortDirection: sortDirection,
      filters: requestFormatFilter(filters),
      queryString: queryString,
    };

    fetchPost(ContentBackendPath + FriendlyBackendPostfix + SearchBackendPostfix, body)
      .then((resp) => {
        setPayload(resp);
      })
      .catch((resp) => {
        toast.error(resp.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const gameMultiSelectRef = useRef(null);

  const paginationWrapper = (autoScroll, allowInputChange) => {
    if (!payload) {
      return;
    }

    const onChange = () => {
      if (autoScroll) {
        gameMultiSelectRef.current.jumpToSection();
      }
    };

    return (
      <Paginator
        page={page}
        setPage={setPage}
        currentPageSize={payload.entries && payload.entries.length}
        pageWindowSize={pageSize}
        totalEntries={payload && payload.total}
        allowInputChange={allowInputChange}
        onChange={onChange}
      />
    );
  };

  return (
    <>
      <Title>Translations</Title>
      <Loader isLoading={isLoading} />

      <div className="lg:grid lg:grid-cols-12 gap-3">
        <div className="col-span-12">
          <FriendlyFilterCard
            filters={filters}
            setFilters={setFilters}
            setQueryString={setQueryString}
            searchUri={ContentBackendPath}
            fieldsUri={ContentBackendPath + FieldValuesBackendPostfix}
            onClick={hackRequest}
            metadata={metadata}
          />
        </div>

        <InvisibleScroll ref={gameMultiSelectRef} />

        <div className="col-span-12">
          <div className="flex items-center justify-end">{paginationWrapper(false, true)}</div>
          <FriendlyTable
            data={payload && payload.entries}
            titleKey="title"
            thumbnailKey="thumbnailUrl.url"
            preambleKey="description"
            gotoIdPath={DatabaseFrontendPath + ContentFrontendPath + EntryPostfix}
            columns={columns}
          />
          <div className="flex items-center justify-end">{paginationWrapper(true, false)}</div>
        </div>
      </div>
    </>
  );
}
