/* LIBS */
import React, { useState, useContext } from "react";
import { Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";

/* ASSETS */
import logo from "./assets/logo.png";
import logo2 from "./assets/logo2.png";
import twitter from "./assets/twitter_logo.png";
import discord from "./assets/discord_logo.png";

/* CUSTOMS */
import Button from "components/button";
import { News, AdvancedSearch, Search, Submission, Help, UserRegistration } from "./pages";
import QueueMain from "./pages/queue";
import ImageHover from "components/imageHover";
import AprilFoolsPopupBox from "aprilFools";

/* CONSTANTS */
import {
  HomeFrontendPath,
  DatabaseFrontendPath,
  AdvancedSearchFrontendPath,
  SubmissionFrontendPath,
  ListPostfix,
  HelpFrontendPath,
  ForumFrontendPath,
  SubmissionQueueFrontendPath,
  LoginFrontendPath,
  ContentFrontendPath,
  UserFrontendPath,
  AdminFrontendPath,
  LogoutFrontendPath,
} from "./constants/routing/frontend";
import { waitTime } from "./constants/toasty";
import { PermissionAdmin } from "constants/permissions";

/* ICONS */
import HomeIcon1 from "assets/icon-home-1.png";
import HomeIcon2 from "assets/icon-home-2.png";

import ForumIcon1 from "assets/icon-forum-1.png";
import ForumIcon2 from "assets/icon-forum-2.png";

import SearchIcon1 from "assets/icon-search-1.png";
import SearchIcon2 from "assets/icon-search-2.png";

import SearchAdvancedIcon1 from "assets/icon-search-advanced-1.png";
import SearchAdvancedIcon2 from "assets/icon-search-advanced-2.png";

import UploadIcon1 from "assets/icon-upload-1.png";
import UploadIcon2 from "assets/icon-upload-2.png";

import HelpIcon1 from "assets/icon-help-1.png";
import HelpIcon2 from "assets/icon-help-2.png";

import LoginIcon1 from "assets/icon-login-1.png";
import LoginIcon2 from "assets/icon-login-2.png";

import LogoutIcon1 from "assets/icon-logout-1.png";
import LogoutIcon2 from "assets/icon-logout-2.png";

import QueueIcon1 from "assets/icon-queue-1.png";
import QueueIcon2 from "assets/icon-queue-2.png";

import AdminIcon1 from "assets/icon-admin-1.png";
import AdminIcon2 from "assets/icon-admin-2.png";

import { CiMenuBurger } from "react-icons/ci";

import { FaAngleRight as ExpandIcon } from "react-icons/fa6";
import { FaAngleLeft as ContractIcon } from "react-icons/fa6";

/* STYLING */
import "./styling/app.css";
import "react-toastify/dist/ReactToastify.css";
import ThemeToggle from "mode";

/* SERVICE */
import { MenuContext } from "context/menuProvider";
import { UserContext } from "context/user";
import { Admin } from "pages";
import ForumListRoutes from "pages/forum";
import SideMenu from "components/sideMenu";
import ScrollToTop from "components/scrollToTop";

function App() {
  const isAprilFirst = () => {
    const today = new Date();
    return today.getMonth() === 3 && today.getDate() === 1;
  };

  const [isBurgerMenuOpen, setIsBurgerMenuOpen] = useState(false);
  const [aprilFoolsModalOpen, setAprilFoolsModalOpen] = useState(isAprilFirst());

  const { userContext } = useContext(UserContext);
  const { username, permissions } = userContext;

  const { isMenuExpanded, toggleMenu } = useContext(MenuContext);

  const navigate = useNavigate();

  const routes = [
    {
      path: HomeFrontendPath + "/*",
      element: <News />,
    },
    {
      path: DatabaseFrontendPath + "/*",
      element: <Search />,
    },
    {
      path: AdvancedSearchFrontendPath + "/*",
      element: <AdvancedSearch />,
    },
    {
      path: SubmissionFrontendPath + "/*",
      element: <Submission />,
    },
    {
      path: SubmissionQueueFrontendPath + "/*",
      element: <QueueMain />,
    },
    {
      path: HelpFrontendPath + "/*",
      element: <Help />,
    },
    {
      path: UserFrontendPath + "/*",
      element: <UserRegistration />,
    },
    {
      path: AdminFrontendPath + "/*",
      element: <Admin />,
    },
    {
      path: ForumFrontendPath + "/*",
      element: <ForumListRoutes />,
    },
  ];

  const socialMedia = [
    {
      image: twitter,
      url: "https://twitter.com/romhackdoting",
    },
    {
      image: discord,
      url: "https://discord.gg/uAufcgz",
    },
  ];

  const menuBarOptions = [
    {
      label: "Home",
      path: HomeFrontendPath,
      icon: <ImageHover src={HomeIcon1} hoverSrc={HomeIcon2} />,
    },
    {
      label: "Database",
      path: DatabaseFrontendPath + ContentFrontendPath,
      icon: <ImageHover src={SearchIcon1} hoverSrc={SearchIcon2} />,
    },
    {
      label: "Submissions",
      path: SubmissionFrontendPath + ListPostfix,
      icon: <ImageHover src={UploadIcon1} hoverSrc={UploadIcon2} />,
    },
    {
      label: "Advanced Search",
      path: AdvancedSearchFrontendPath + ContentFrontendPath,
      icon: <ImageHover src={SearchAdvancedIcon1} hoverSrc={SearchAdvancedIcon2} />,
    },
    {
      label: "Queue",
      path: SubmissionQueueFrontendPath + ListPostfix,
      icon: <ImageHover src={QueueIcon1} hoverSrc={QueueIcon2} />,
    },
    {
      label: "Forum",
      path: ForumFrontendPath,
      icon: <ImageHover src={ForumIcon1} hoverSrc={ForumIcon2} />,
    },
    {
      label: "Help",
      path: HelpFrontendPath,
      icon: <ImageHover src={HelpIcon1} hoverSrc={HelpIcon2} />,
    },
    {
      label: "Admin",
      path: AdminFrontendPath + UserFrontendPath,
      icon: <ImageHover src={AdminIcon1} hoverSrc={AdminIcon2} />,
      condition: permissions.includes(PermissionAdmin),
    },
    {
      label: "Login",
      path: UserFrontendPath + LoginFrontendPath,
      icon: <ImageHover src={LoginIcon1} hoverSrc={LoginIcon2} />,
      condition: username === "" || username === undefined || username === null,
    },
    {
      label: "Logout",
      path: UserFrontendPath + LogoutFrontendPath,
      icon: <ImageHover src={LogoutIcon1} hoverSrc={LogoutIcon2} />,
      condition: username !== "" && username !== undefined && username !== null,
    },
  ];

  const menuItemOnClick = () => window.scrollTo({ top: 0 });

  const SideBar = () => {
    return (
      <aside
        className={`fixed top-0 left-0 transition-all duration-300 z-50 ${isMenuExpanded ? "w-64" : "w-16"} 
      h-screen -translate-x-full sm:translate-x-0`}
        aria-label="Sidebar"
      >
        <div className="h-full px-3 py-4 overflow-y-auto overflow-x-hidden bg-menuColor">
          {menuBarOptions.map((c, i) => {
            let { label, path, icon, isExternalLink, condition } = c;

            if (condition !== undefined && !condition) {
              return <React.Fragment key={`menuEmpty-${i}`} />;
            }

            const linkClassName = "text-base text-sm text-white hover:no-underline";
            const iconClassName = "w-8 pixelImage mr-3";

            const props = { ...icon.props, className: iconClassName, label: isMenuExpanded ? label : "" };
            let icon1 = React.cloneElement(icon, props);

            // Make sure to have a div height so the icon tilt has some slack space.
            const child = (
              <div data-tooltip-id="my-tooltip" data-tooltip-content={label}>
                <div className={`mb-5 ${i === 0 && "mt-24 "} h-12`}>{icon1}</div>
              </div>
            );

            if (isExternalLink) {
              return (
                <a key={`menu-${i}`} href={path} className={linkClassName} onClick={menuItemOnClick}>
                  {child}
                </a>
              );
            }

            return (
              <Link key={`menu-${i}`} to={path} className={linkClassName} onClick={menuItemOnClick}>
                {child}
              </Link>
            );
          })}
          {isMenuExpanded || <Tooltip id="my-tooltip" place="left" className="z-50" />}

          <div className="flex justify-end">
            <button
              className="p-2 rounded-full bg-textBoxColor hover:bg-textBoxFocusColor"
              onClick={() => {
                toggleMenu(!isMenuExpanded);
              }}
            >
              {isMenuExpanded ? <ContractIcon /> : <ExpandIcon />}
            </button>
          </div>
        </div>
      </aside>
    );
  };

  return (
    <>
      <AprilFoolsPopupBox modalOpen={aprilFoolsModalOpen} setModalOpen={setAprilFoolsModalOpen} />

      {/* Burger Menu */}
      <SideMenu className="bg-menuColor relative h-full mb-2" isOpen={isBurgerMenuOpen} toggleMenu={setIsBurgerMenuOpen}>
        {menuBarOptions.map((c, i) => {
          let { label, path, icon, isExternalLink, condition } = c;

          if (condition !== undefined && !condition) {
            return <React.Fragment key={`menuEmpty-${i}`} />;
          }

          const iconClassName = "pixelImage mr-2";

          const props = { ...icon.props, className: iconClassName, label: label };
          let icon1 = React.cloneElement(icon, props);

          const child = <div className="flex items-center p-4 rounded select-none">{icon1}</div>;

          if (isExternalLink) {
            return (
              <a
                key={`menuExternal-${i}`}
                href={path}
                onClick={() => {
                  setIsBurgerMenuOpen(false);
                  menuItemOnClick();
                }}
                className="hover:no-underline "
              >
                {child}
              </a>
            );
          }

          return (
            <Link
              key={`menuInternal-${i}`}
              to={path}
              onClick={() => {
                setIsBurgerMenuOpen(false);
                menuItemOnClick();
              }}
              className="hover:no-underline"
            >
              {child}
            </Link>
          );
        })}
      </SideMenu>
      {/* Burger Menu */}

      {SideBar()}

      <div className={`transition-all duration-300 ${isMenuExpanded ? "sm:ml-64" : "sm:ml-16"}`}>
        {/* Navbar*/}
        {/* https://codepen.io/bradtraversy/pen/JgXqBL*/}
        <div className="flex flex-col min-h-full min-h-[100vh] ">
          <nav className="flex items-center justify-between flex-wrap menu-bar p-3 shadow-lg">
            <div className="flex space-x-4 ">
              <Button onClick={() => setIsBurgerMenuOpen(true)} className="rounded sm:hidden">
                <CiMenuBurger />
              </Button>
              <span
                className="cursor-pointer"
                onClick={() => {
                  navigate(`${HomeFrontendPath}`);
                }}
              >
                <img src={isAprilFirst() ? logo2 : logo} alt="" className="h-16 pixelImage" />
              </span>
            </div>

            <div>
              <div className="mt-3 w-full block flex-grow sm:flex sm:items-center sm:w-auto">
                <div className="sm:flex-grow">
                  <div className="flex items-center justify-end gap-3 mr-3">
                    <ThemeToggle />

                    {socialMedia.map((c, i) => {
                      return (
                        <div key={`socialMedia-${i}`}>
                          <a target="_blank" href={c.url} rel="noreferrer">
                            <img src={c.image} alt="" className="pixelImage" />
                          </a>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </nav>

          <div className="pr-3 pl-3">
            <div>
              <ScrollToTop/>
              <Routes>
                {routes.map(({ path, element }, i) => {
                  return <Route key={`appRoute-${i}`} path={path} element={element} exact />;
                })}
              </Routes>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer position="bottom-right" autoClose={waitTime} />
    </>
  );
}

export default App;
