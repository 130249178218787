/** LIBS */
import React from "react";
import PropTypes from "prop-types";
import MultiSelectAutocomplete from "components/multiSelectAutocomplete";
import { AuthorBackendPath, ContentBackendPath, FieldValuesBackendPostfix } from "constants/routing/backend";
import TextBox from "components/textBox/index";

/* CUSTOMS */
import Button from "components/button/index";

/* ICONS */
import { FaXmark  as DeleteIcon } from "react-icons/fa6";

export default function CreditItem({ creditList, setCreditList, index }) {

  var latestLabel = "";

  return (
    <div className="grid grid-cols-12">
      <MultiSelectAutocomplete
        className="m-1 lg:col-span-4 col-span-12"
        placeholder="Credit"
        field="title"
        searchUri={AuthorBackendPath}
        searchPostfix={FieldValuesBackendPostfix}

        label={[creditList[index].creditName]}
        value={[creditList[index].creditId]}

        setLabel={ (x) => {
          latestLabel = x;
        }}

        setValue={ (x) => {
          const temp = [...creditList];
          temp[index].creditId = x;
          temp[index].creditName = latestLabel;
          setCreditList(temp);
        }}

        isCreatable={true}
        isMulti={false}
      />

      <MultiSelectAutocomplete
        className="m-1 lg:col-span-4 col-span-12"
        placeholder="Type"
        field="credits.type"
        searchUri={ContentBackendPath}
        searchPostfix={FieldValuesBackendPostfix}

        label={[creditList[index].type]}

        setLabel={ (x) => {
          const temp = [...creditList];
          temp[index].type = x;
          setCreditList(temp);
        }}

        isCreatable={true}
        isMulti={false}
      />

      <div className="m-1 lg:col-span-3 col-span-12">
        <TextBox id={"note-box"} 
          className="w-full"
          placeholder={"Note"} 
          onChange={(x) =>{
            const temp = [...creditList];
            temp[index].comment = x;
            setCreditList(temp);
          }} 
        />
      </div>

      <div className="m-1 lg:col-span-1 col-span-12 flex items-center justify-end">
        <Button className="rounded-md" secondary={true} onClick={() =>{
          const temp = [...creditList];
          temp.splice(index, 1);
          setCreditList(temp);
        }}>
          {<DeleteIcon />}
        </Button>
      </div>
    </div>
  );
}

CreditItem.propTypes = {
  creditList: PropTypes.array.isRequired,
  setCreditList: PropTypes.func.isRequired,

  index: PropTypes.number.isRequired,
};
