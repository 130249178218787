/* LIBS */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

/* CUSTOMS */
import "./popup.css";
import Button from "components/button/index";

/* ICONS */
import { FaChevronLeft as LeftIcon, FaChevronRight as RightIcon } from "react-icons/fa";

export default function Gallery({ imageUrls }) {
  const [pageNumber, setPageNumber] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  const className = "inline-flex shadow-md hover:shadow-lg focus:shadow-lg mr-3 ml-3 mb-3 mt-3 overflow-hidden rounded-b-md rounded-t-md";

  const left = () => {
    if (pageNumber === 0) {
      setPageNumber(imageUrls.length - 1);
      return;
    }
    setPageNumber(pageNumber - 1);
  };

  const right = () => {
    if (pageNumber === imageUrls.length - 1) {
      setPageNumber(0);
      return;
    }
    setPageNumber(pageNumber + 1);
  };

  useEffect(() => {
    const handleKeyUp = (event) => {
      if (!isOpen) {
        return;
      }

      switch (event.key) {
      case "ArrowLeft":
        left();
        break;
      case "ArrowRight":
        right();
        break;
      case "Escape":
        setIsOpen(false);
        break;
      default:
        break;
      }
    };

    // Attach the event listener to the window
    window.addEventListener("keyup", handleKeyUp);

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, [pageNumber]);

  return (
    <>
      <div className="container h-auto mb-4">
        <div className="grid grid-cols-4 h-auto">
          {imageUrls.map((x, i) => {
            return (
              <img
                key={`screenShot-${i}`}
                className="p-1 cursor-pointer"
                src={x}
                alt=""
                loading="lazy"
                onClick={() => {
                  setIsOpen(true);
                  setPageNumber(i);
                }}
              />
            );
          })}
        </div>
      </div>
      <div
        onClick={() => setIsOpen(false)}
        style={{ zIndex: 1000 }}
        className={`h-full fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 ${isOpen ? "" : "hidden"}`}
      >
        <div className={`h-full w-full ${isOpen ? "" : "hidden"}`}>
          <div className={"flex items-center justify-center h-full w-full"}>
            <Button
              className={className}
              onClick={(e) => {
                e.stopPropagation();
                left();
              }}
            >
              <LeftIcon />
            </Button>

            <img
              className="p-1 object-contain max-h-[80%] max-w-[60%] h-full pixelImage aspect-auto"
              src={imageUrls[pageNumber]}
              alt=""
              loading="lazy"
              onClick={(e) => e.stopPropagation()}
            />
            <Button
              className={className}
              onClick={(e) => {
                e.stopPropagation();
                right();
              }}
            >
              <RightIcon />
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}

Gallery.propTypes = {
  imageUrls: PropTypes.arrayOf(PropTypes.string),
};
