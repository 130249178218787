import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { BsThreeDotsVertical } from "react-icons/bs";

const DropdownDot = ({ options }) => {
  const [show, setShow] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => setShow(!show);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShow(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="relative" ref={dropdownRef}>
      <button
        onClick={toggleDropdown}
        className="p-2 rounded-full hover:bg-textBoxFocusColor focus:outline-none"
      >
        <BsThreeDotsVertical className="text-xl" />
      </button>
      {show && (
        <div className="absolute right-0 mt-2 w-48 bg-textBoxColor border border-borderColor shadow-lg rounded-md z-50">
          {options.map((option, index) => (
            <div
              key={index}
              className="p-2 cursor-pointer hover:bg-textBoxFocusColor text-contrastChangeText"
              onClick={() => {
                option.onClick();
                setShow(false); 
              }}
            >
              {option.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

// Define prop types for the Dropdown component
DropdownDot.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
    })
  ).isRequired,
};

export default DropdownDot;