/* LIBS */
import React, { useState, useEffect, useContext } from "react";
import { toast } from "react-toastify";
import Markdown from "react-markdown";
import Button from "components/button";
import { useLocation, useNavigate } from "react-router-dom";

/* CUSTOMS */
import MultiSelectAutocomplete from "components/multiSelectAutocomplete";
import TextArea from "components/textArea";
import Card from "components/card";
import Heading from "components/heading";
import Title from "components/title";
import { fetchPost } from "lib/fetch";
import Loader from "components/loader";
import CheckBox from "components/checkBox";
import UnsavedChangesWarning from "components/unsavedChangesWarning";

/* CONSTANTS */
import {
  GetBackendPostfix,
  FieldValuesBackendPostfix,
  QueueBackendPostfix,
  CreateBackendPostfix,
  QueueBackendPath,
  UpdateBackendPostfix,
  BlockListBackendPath,
} from "constants/routing/backend";
import { EntryPostfix, ListPostfix, SubmissionQueueFrontendPath } from "constants/routing/frontend";
import { PermissionCreateTags } from "constants/permissions";
import { blockListItemType } from "constants/models";

/* SERVICES */
import { UserContext } from "context/user";
import MultiSelect from "components/multiSelect";
import { ToLabelValue, ToLabelValueFromMap } from "lib/strings";

export default function BlockListSubmission() {
  const [isLoading, setIsLoading] = useState(false);

  const [entityId, setEntityId] = useState("");

  const [description, setDescription] = useState("");
  const [itemType, setItemType] = useState("");

  const [items, setItems] = useState([]);
  const [tags, setTags] = useState([]);

  const [isEnabled, setIsEnabled] = useState(true);

  const [historyComment, setHistoryComment] = useState("");

  const { userContext } = useContext(UserContext);
  const { permissions } = userContext;

  // Function to parse query parameters
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const queueId = queryParams.get("queueId");
  const entityQueryId = queryParams.get("entityId");

  const navigate = useNavigate();

  useEffect(() => {
    if (entityQueryId && queueId) {
      toast.error("Cannot edit both queue and author");
      return;
    }

    if (entityQueryId) {
      contentRequest(entityQueryId);
    }

    if (queueId) {
      queueRequest(queueId);
    }
  }, []);

  useEffect(() => {
    setEntityId(entityQueryId);
  }, [entityQueryId]);

  const markdownWidth = "md:col-span-3 col-span-6";

  const validationCheck = () => {
    var pass = true;

    if (tags.length === 0) {
      toast.error("Must select at least one tag");
      pass = false;
    }

    return pass;
  };

  const queueSubmitRequest = () => {
    if (!validationCheck()) {
      return;
    }

    const isUpdate = queueId !== null;

    var titleTemp = "";

    if (items.length === 1) {
      titleTemp = items[0];
    }  else {
      titleTemp = "Multiple";
    }

    const params = {
      entityId: entityId,
      queueId: queueId,

      isEnabled: isEnabled,
      title: titleTemp,
      itemType: itemType,
      items:items,
      tags: tags,
      description: description,
      historyComment: historyComment,
    };

    const postfix = isUpdate ? UpdateBackendPostfix : CreateBackendPostfix;

    setIsLoading(true);
    fetchPost(BlockListBackendPath + QueueBackendPostfix + postfix, params)
      .then(() => {
        toast.success(`Entry ${isUpdate ? "updated" : "created"}.`);
        if (queueId) {
          navigate(SubmissionQueueFrontendPath + EntryPostfix + queueId);
        } else {
          navigate(SubmissionQueueFrontendPath + ListPostfix);
        }
      })
      .catch((resp) => {
        toast.error(resp.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const contentRequest = (id) => {
    const body = {
      id: id,
    };

    setIsLoading(true);

    fetchPost(BlockListBackendPath + GetBackendPostfix, body)
      .then((d) => {
        setHooks(d);
      })
      .catch((resp) => {
        toast.error(resp.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const queueRequest = (id) => {
    const body = {
      id: id,
    };

    setIsLoading(true);

    fetchPost(QueueBackendPath + GetBackendPostfix, body)
      .then((e) => {
        if (!e.queueEntry.data) {
          throw new Error("Queued data is empty.");
        }

        const d = JSON.parse(e.queueEntry.data);
        setHooks(d);
        setHistoryComment(e.queueEntry.historyComment);
      })
      .catch((resp) => {
        toast.error(resp.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const setHooks = (d) => {
    setDescription(d.description);
    setTags(d.tags);
    setIsEnabled(d.isEnabled);
    setItemType(d.itemType);
    setItems(d.items);
  };

  return (
    <>
      <Loader isLoading={isLoading} />
      <Title className={"col-span-6"}>{queueId || entityQueryId ? "Edit Block Entry" : "Submit Block Entry"}</Title>
      <UnsavedChangesWarning />

      <Card className="m-8" title={"General Information"}>
        <div className="grid grid-cols-6 gap-4 text-start">
          <MultiSelect
            className="col-span-12"
            placeholder="Type"
            isMulti={false}
            options={ToLabelValue(blockListItemType)}
            onSelect={(e) => {
              setItemType(e.value);
            }}
            value={ToLabelValue(itemType)}
            onInputChange={() => {}}
          />

          <MultiSelect
            className="col-span-12"
            placeholder="Items"
            isMulti={true}
            onCreate={(e) => {
              setItems(e.map((x) => x.label));
            }}
            onRemove={(_, meta) => {
              const temp = [...items];
              const index = temp.indexOf(meta.removedValue.value);
              temp.splice(index, 1);
              setItems(temp);
            }}
            options={null}
            value={ToLabelValueFromMap(items)}
            onInputChange={() => {}}
          />

          <MultiSelectAutocomplete
            className="max-lg:col-span-6 md:col-span-4"
            placeholder="Tags"
            field="tags"
            searchUri={BlockListBackendPath}
            searchPostfix={FieldValuesBackendPostfix}
            label={tags}
            isMulti={true}
            setLabel={setTags}
            isCreatable={permissions.includes(PermissionCreateTags)}
          />
          <div>
            <CheckBox className="mt-3 mr-2" checked={isEnabled} onChange={setIsEnabled} label={"Is Enabled"} />
          </div>
        </div>
      </Card>

      <Card className="m-8" title={"Description"}>
        <div className="grid grid-cols-6 gap-4 text-start">
          <div className={markdownWidth}>
            <Heading>Markdown</Heading>
            <TextArea
              noResize={true}
              wrapperClassName="w-full"
              className={"h-80"}
              value={description}
              onChange={(value) => {
                setDescription(value);
              }}
            />
          </div>
          <div className={markdownWidth}>
            <Heading>Preview</Heading>
            <div className="col-span-3 overflow-scroll h-80">
              <Markdown className="w-full">{description}</Markdown>
            </div>
          </div>
        </div>
      </Card>

      <Card className="m-8" title={"History Comment"}>
        <div className="w-full text-start">
          <TextArea
            placeholder="Comment"
            noResize={true}
            wrapperClassName="w-full"
            className={"h-20"}
            value={historyComment}
            onChange={setHistoryComment}
          />
        </div>
      </Card>

      <div className="flex justify-end mr-5">
        <Button className={"mr-3 ml-3 mb-3 mt-3 overflow-hidden rounded-b-md rounded-t-md"} onClick={queueSubmitRequest}>
          Submit
        </Button>
      </div>
    </>
  );
}
