/** LIBS */
import React from "react";
import { Route, Routes } from "react-router-dom";

/* CUSTOMS */
import SeoHeader from "components/seoHeader";

/* CONSTANTS */
import { ListPostfix } from "constants/routing/frontend";
import QueueGridView from "./grid";
import QueueEntry from "./entry";

export default function QueueMain() {
  const hiddenLinks = [
    {      
      pathPart: ListPostfix,
      element: <QueueGridView />,
    },
    {
      pathPart: "/entry/:id",
      element: <QueueEntry />,
    },
  ];

  return (
    <>
      <SeoHeader pageTitle={"Submission"} />

      <Routes>
        
        {hiddenLinks.map(({ pathPart, element }, i) => {
          return <Route key={`queueRoute-${i}`} path={pathPart} exact element={element} />;
        })}
      </Routes>
    </>
  );
}
