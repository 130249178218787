/**
 * Object containing GBA-specific data and functions
 */

// @ts-check
import Platform from "./platform";

const externalHeaderSize = 0x200;

export default class GenPlatform extends Platform {
  /**
   * @returns {boolean}
   */
  hasExternalHeader() {
    return getRomInfo(this.rom).externalHeader;
  }

  /**
   * @returns {number}
   */
  externalHeaderLength() {
    if (!getRomInfo(this.rom).externalHeader){
      return 0x0;
    }

    return externalHeaderSize; 
  }

  /**
   * Converts the rom to a normalized form.
   * @returns {object} Information about the conversion
   */
  normalize() {
    var { externalHeader, interleaved } = getRomInfo(this.rom);

    var message = "";
    var romOut = this.rom;

    if (interleaved) {
      message = externalHeader ? "SMD (headered)" : "SMD (no header)";
    } else {
      message = externalHeader ? "BIN (headered)" : "Genesis ROM image (BIN)";
    }

    if (externalHeader){
      romOut = romOut.slice(this.externalHeaderLength());
    }

    if (interleaved) {
      // TODO
    }

    return { message: message, rom: romOut };
  }
}

/** strings found in ROMs used to identify layout */
const romIdentifiers = [
  {
    sega32X: false,
    interleaved: false,
    knownNames: ["SEGA GENESIS", "SEGA MEGADRIVE", "SEGA MEGA DRIVE"],
    offset: 0x100,
    headeredOffset: 0x200,
  },
  {
    sega32X: true,
    interleaved: false,
    knownNames: ["SEGA 32X"],
    offset: 0x100,
    headeredOffset: 0x200,
  },
  {
    sega32X: false,
    interleaved: true,
    knownNames: ["SG EEI", "SG EARV", "SG EADIE", "EAGNSS", "EAMGDIE", "EAMG RV"],
    offset: 0x80,
    headeredOffset: 0x280,
  },
  {
    sega32X: true,
    interleaved: true,
    knownNames: ["SG 2", "EA2"],
    offset: 0x80,
    headeredOffset: 0x280,
  },
];

/**
 * Gets ROM layout and platform (gen vs. 32x) information based on internal header.
 * @param {Uint8Array} romImage
 * @returns {{interleaved: boolean, internalHeader: boolean, externalHeader: boolean, sega32X: boolean}}
 */
function getRomInfo(romImage) {
  if (romImage.length >= 0x400) {

    for (var i = 0; i < romIdentifiers.length; i++) {
      var identifier = romIdentifiers[i];

      // Check at locations for both with and without external header
      var unheaderedText = parseAscii(romImage, identifier.offset, 0x10).replace("_", " ").trim();
      var headeredText = parseAscii(romImage, identifier.headeredOffset, 0x10).replace("_", " ").trim();

      for (var iKnownName = 0; iKnownName < identifier.knownNames.length; iKnownName++) {
        var knownName = identifier.knownNames[iKnownName];

        var unheaderedMatch = unheaderedText.startsWith(knownName);
        var headeredMatch = headeredText.startsWith(knownName);

        if (headeredMatch || unheaderedMatch) {
          return {
            interleaved: identifier.interleaved,
            sega32X: identifier.sega32X,
            internalHeader: true,
            externalHeader: headeredMatch
          };
        }
      }
    }
  }
    
  return {
    interleaved: false,
    sega32X: false,
    internalHeader: false,
    externalHeader: false
  };
}

/**
 * Parses ASCII-encoded text from a ROM image. Character values above 0x7F are undefined.
 * @param {Uint8Array} rom
 * @param {number} offset
 * @param {number} length
 * @param {boolean} [ignoreTerminator] - If true, the returned string will not be truncated at the first null terminator character
 * @returns {string}
 */
function parseAscii(rom, offset, length, ignoreTerminator) {
  // Create a view into the rom
  var buffer = rom.buffer;
  var start = Math.min(rom.byteOffset + offset, buffer.byteLength); // no further than end of buffer
  var len = Math.min(buffer.byteLength - start, length); // no longer than to end of buffer
  
  const view = new Uint8Array(buffer, start, len);
  
  // Use this view as an array of character codes
  const result = Array.from(view).map(charCode => String.fromCharCode(charCode)).join("");
      
  // Truncate the string at the first null terminator, if applicable
  if (!ignoreTerminator) {
    var indexOfNull = result.indexOf("\x00");
    if (indexOfNull >= 0) return result.substr(0, indexOfNull);
  }
  
  return result;
}

