/** LIBS */
import React from "react";
import PropTypes from "prop-types";
import FilterItem from "./filterItem";
import { formatDbFieldString } from "lib/strings";

export default function AdvancedFilter({
  filters,
  setFilters,
  metadata,
  searchUri,
}) {

  if (!metadata){
    return;
  }

  return (
    <div>
      {metadata && Object.keys(metadata.filters).map((fieldName, fieldNameI) => {
        if (fieldName.endsWith("Id")&& fieldName !== "legacyId"){
          return <React.Fragment key={`d-${fieldNameI}`}/>;
        }

        return (
          <div key={`d-${fieldNameI}`}>
            <h3 className={"text-left"}>{formatDbFieldString(fieldName)}</h3>

            {filters && filters.map((filterItem, filterItemI) => {
              const key = `${fieldName}-${fieldNameI}-${filterItemI}`;

              if (filterItem.field !== fieldName) {
                return <div key={key}/>;
              }

              return(
                <div key={filterItem}>
                  {<FilterItem 
                    key={`${filterItem.value}-${filterItemI}`} 
                    filterItem={filterItem} 
                    action={"Delete"} 
                    index={filterItemI} filters={filters}
                    setFilters={setFilters}
                    searchUri={searchUri}
                  />}
                </div>
              );
            })}
            
            {<FilterItem 
              key={`newFilterItem-${fieldNameI}`} 
              filterItem={{field: fieldName}} 
              action={"Add"} 
              index={null} 
              filters={filters} 
              setFilters={setFilters}
              searchUri={searchUri}
            />}
          </div>
        );
      })}
    </div>
  );
}

AdvancedFilter.propTypes = {
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      operator: PropTypes.string,
      field: PropTypes.string.isRequired,
      value: PropTypes.string,
      disabled: PropTypes.bool,
    })
  ).isRequired,

  setFilters: PropTypes.func,

  metadata: PropTypes.object,

  searchUri: PropTypes.string.isRequired,
};
