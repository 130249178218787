/**
 * Object containing GG-specific data and functions
 */

// @ts-check
import Platform from "./platform";

export default class GgPlatform extends Platform {
  /**
   * @returns {boolean}
   */
  hasExternalHeader() {
    return false;
  }

  /**
   * @returns {number}
   */
  externalHeaderLength() {
    return 0x0; 
  }
}
