/** LIBS */
import React from "react";
import { Route, Routes } from "react-router-dom";

/* CUSTOMS */
import ButtonLinksTabbed from "components/buttonLinksTabbed";
import { ContentGridView } from "./content";
import { GameGridView } from "./game";
import { AuthorGridView } from "./author";
import { ChangeGridView } from "./change";

import SeoHeader from "components/seoHeader";

/* CONSTANTS */
import {
  AdvancedSearchFrontendPath,
  ContentFrontendPath,
  GamesFrontendPath,
  AuthorFrontendPath,
  ChangesFrontendPath,
} from "constants/routing/frontend";

export default function DatabaseListRoutes() {
  const tabbedLinks = [
    {
      text: "Content",
      path: AdvancedSearchFrontendPath + ContentFrontendPath,
      pathPart: ContentFrontendPath,
      element: <ContentGridView />,
    },
    {
      text: "Games",
      path: AdvancedSearchFrontendPath + GamesFrontendPath,
      pathPart: GamesFrontendPath,
      element: <GameGridView />,
    },
    {
      text: "Authors",
      path: AdvancedSearchFrontendPath + AuthorFrontendPath,
      pathPart: AuthorFrontendPath,
      element: <AuthorGridView />,
    },
    {
      text: "Changes",
      path: AdvancedSearchFrontendPath + ChangesFrontendPath,
      pathPart: ChangesFrontendPath,
      element: <ChangeGridView />,
    },
  ];

  return (
    <>
      <SeoHeader 
        pageTitle={"Advanced Search"} 
      />
      <ButtonLinksTabbed id="advancedSearch" links={tabbedLinks} />

      <Routes>
        {tabbedLinks.map(({ pathPart, element }, i) => {
          return (
            <Route
              key={`blocklistRoute1-${i}`}
              path={pathPart}
              exact
              element={element}
            />
          );
        })}
      </Routes>
    </>
  );
}
