/** LIBS */
import React, { useState, useEffect, useContext, useRef } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";

/* CUSTOMS */
import SeoHeader from "components/seoHeader";
import Title from "components/title";
import Paginator from "components/paginator";
import { fetchPost } from "lib/fetch";
import { requestFormatFilter } from "lib/elastic";
import Button from "components/button";
import { SortableTable } from "components/sortableTable";
import InvisibleScroll from "components/invisibleScroll";

/* CONSTANTS */
import { pageSize } from "constants/models";
import Loader from "components/loader";
import { ForumBackendPath, MetadataBackendPostfix, SearchBackendPostfix } from "constants/routing/backend";
import { CreatePostfix, ForumFrontendPath } from "constants/routing/frontend";

/* ICONS */
import ForumIcon1 from "assets/icon-forum-1.png";

/* SERVICES */
import { UserContext } from "context/user";

export default function ForumTopicAdvancedSearch() {
  const [page, setPage] = useState(0);
  const [payload, setPayload] = useState(null);
  const [metadata, setMetadata] = useState(null);
  const [sortField, setSortField] = useState("updatedDate");
  const [sortDirection, setSortDirection] = useState("desc");

  const [isLoading, setIsLoading] = useState(false);

  const { tag } = useParams();

  const { userContext } = useContext(UserContext);
  const { username, lastReadDateTime } = userContext;

  useEffect(() => {
    metadataRequest();
    hackRequest();
  }, []);

  const metadataRequest = () => {
    setIsLoading(true);
    fetchPost(ForumBackendPath + "/topic" + MetadataBackendPostfix, null)
      .then((resp) => {
        setMetadata(resp);
      })
      .catch((resp) => {
        toast.error(resp.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const hackRequest = () => {
    setIsLoading(true);

    let matchFilter = requestFormatFilter([
      {
        field: "updatedDate",
        operator: "must",
        value: lastReadDateTime,
        ruleType: "greaterThan",
      },
    ]);

    const body = {
      page: page,
      sortField: sortField,
      sortDirection: sortDirection,
      filters: matchFilter,
    };

    fetchPost(ForumBackendPath + "/topic" + SearchBackendPostfix, body)
      .then((resp) => {
        setPayload(resp);
      })
      .catch((resp) => {
        toast.error(resp.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const columns = [
    {
      label: "Status",
      field: "",
      width: "w-20",
      dataType: "custom",
      custom: (data) => {
        return <img src={ForumIcon1} className="pixelImage p-2 scale-[1.7]" alt="" />;
      },
    },
    {
      label: "Topic",
      idField: "id",
      field: "title",
      width: "w-96 max-xl:w-auto",
      dataType: "link",
      to: (data) => {
        return `${ForumFrontendPath}/topic/`;
      },
    },
    {
      label: "Created By",
      idField: "createdById",
      field: "createdBy",
      width: "w-auto max-xl:hidden",
      dataType: "link",
      to: (data) => {
        return "TODO"; //`/database/${data.entityType}/entry/`;
      },
    },
    {
      label: "Created Time",
      field: "createdDate",
      width: "w-auto max-xl:hidden",
      dataType: "dateTimeAgo",
    },
    {
      label: "Last Post By",
      idField: "updatedById",
      field: "updatedBy",
      width: "w-auto",
      dataType: "link",
      to: (data) => {
        return "TODO"; //`/database/${data.entityType}/entry/`;
      },
    },
    {
      label: "Last Post Time",
      field: "updatedDate",
      width: "w-auto max-sm:hidden",
      dataType: "dateTimeAgo",
    },
    {
      label: "Replies",
      field: "posts",
      width: "w-28 max-xl:hidden",
      dataType: "write",
    },
    {
      label: "Views",
      field: "views",
      width: "w-28 max-xl:hidden",
      dataType: "write",
    },
  ];

  const gameMultiSelectRef = useRef(null);

  const paginationWrapper = (autoScroll, allowInputChange) => {
    if (!payload) {
      return;
    }

    const onChange = () => {
      if (autoScroll) {
        gameMultiSelectRef.current.jumpToSection();
      }
    };

    return (
      <Paginator
        page={page}
        setPage={setPage}
        currentPageSize={payload.entries && payload.entries.length}
        pageWindowSize={pageSize}
        totalEntries={payload && payload.total}
        allowInputChange={allowInputChange}
        onChange={onChange}
      />
    );
  };

  if (!payload) {
    return;
  }

  return (
    <>
      <SeoHeader pageTitle={`Forum - ${tag}`} />
      <Title>{tag}</Title>
      <Loader isLoading={isLoading} />

      <div className="flex items-center justify-between">
        {username ? (
          <Link to={ForumFrontendPath + "/topic" + CreatePostfix + "/" + tag}>
            <Button className="rounded-lg">New Topic</Button>
          </Link>
        ) : (
          <div></div>
        )}
        <div className="flex items-center justify-end">{paginationWrapper(false, true)}</div>
      </div>

      <InvisibleScroll ref={gameMultiSelectRef} />

      <SortableTable
        data={payload && payload.entries}
        metadataColumn={metadata && metadata.columns}
        columns={columns}
        setSortField={setSortField}
        setSortDirection={setSortDirection}
        sortField={sortField}
        sortDirection={sortDirection}
      />
      <div className="flex items-center justify-end">{paginationWrapper(false, true)}</div>
    </>
  );
}
