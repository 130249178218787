/** LIBS */
import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import Markdown from "react-markdown";

/* CUSTOMS */
import Heading from "components/heading";
import TextArea from "components/textArea";

const CommentBox = forwardRef(({postText, setPostText}, ref) => {
  const markdownWidth = "md:col-span-3 col-span-6";

  return(
    <>
      <div className="grid grid-cols-6 gap-4 text-start">
        <div className={`${markdownWidth} w-full`}>
          <Heading>Markdown</Heading>
          <TextArea
            ref={ref}
            placeholder="Message"
            noResize={true}
            wrapperClassName="w-full col-span-6"
            className={"h-60"}
            value={postText}
            onChange={(value) => {
              setPostText(value);
            }}
          />
        </div>
        <div className={`${markdownWidth} w-full`}>
          <Heading>Preview</Heading>
          <div className={`${markdownWidth} overflow-scroll w-full`}>
            <Markdown className={"w-full h-60"}>{postText}</Markdown>
          </div>
        </div>
      </div>
    </>
  );
});

CommentBox.displayName = "TextBox";

CommentBox.propTypes = {
  title: PropTypes.string.isRequired,
  postText: PropTypes.string,
  setPostText: PropTypes.func,
  className: PropTypes.string,
};
  
export default CommentBox;