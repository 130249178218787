/** LIBS */
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Markdown from "react-markdown";
import { Link } from "react-router-dom";

/* CUSTOMS */
import Card from "components/card";
import { fetchPost } from "lib/fetch";
import Title from "components/title";
import Loader from "components/loader";
import SeoHeader from "components/seoHeader";
import DeletionNoticeCard from "../deletionNotice";
import { FriendlyColumns } from "components/friendlyColumns";
import Button from "components/button";

/* CONSTANTS */
import { GetBackendPostfix, ReviewBackendPath } from "constants/routing/backend";
import { getLinkFromLegacyId, removeMarkdownFormatting } from "lib/strings";
import HistoryCard from "pages/history";
import {
  AdminFrontendPath,
  ContentFrontendPath,
  DatabaseFrontendPath,
  EntryPostfix,
  ReviewFrontendPath,
  UserFrontendPath,
} from "constants/routing/frontend";

/* SERVICES */
import QueueManageButtons from "../queueManageButton";

export default function ReviewEntry() {
  const [payloadReview, setPayloadReview] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const { id } = useParams();

  const margin = "ml-1 mb-3";

  useEffect(() => {
    hackGetRequest();
  }, []);

  const hackGetRequest = () => {
    setIsLoading(true);

    const body = {
      id: id,
    };

    fetchPost(ReviewBackendPath + GetBackendPostfix, body)
      .then((resp) => {
        setPayloadReview(resp);
      })
      .catch((resp) => {
        toast.error(resp.message);
        setPayloadReview(null);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const reviewText = () => {
    if (!payloadReview || !payloadReview.review) {
      return;
    }

    return (
      <Card className={margin} title={"Review Text"}>
        <div className="max-h-80 overflow-y-auto">
          <Markdown className="text-left mr-3">{payloadReview && payloadReview.review}</Markdown>
        </div>
      </Card>
    );
  };

  const reviewResponse = () => {
    if (!payloadReview || !payloadReview.reviewOwnerResponse) {
      return;
    }

    return (
      <Card className={margin} title={"Review Response"}>
        <div className="max-h-80 overflow-y-auto">
          <Markdown className="text-left mr-3">{payloadReview && payloadReview.reviewOwnerResponse}</Markdown>
        </div>
      </Card>
    );
  };


  const linksCard = () => {
    if (!payloadReview) {
      return;
    }

    const columns = [
      {
        label: "Title",
        field: "title",
        width: "col-span-12",
        dataType: "write",
      },
      {
        label: "Username",
        idField: "userId",
        field: "username",
        width: "col-span-6",
        dataType: "link",
        to: AdminFrontendPath + UserFrontendPath + EntryPostfix,
      },
      {
        label: "Created Time",
        field: "createdDate",
        width: "col-span-6",
        dataType: "dateTime",
      },
      {
        label: "Updated Time",
        field: "updatedDate",
        width: "col-span-6",
        dataType: "dateTime",
      },
      {
        label: "Legacy Id",
        field: "legacyId",
        width: "col-span-6",
        dataType: "custom",
        custom: (data) => {
          return (
            <a className="text-sm" href={getLinkFromLegacyId(data)}>
              {data}
            </a>
          );
        },
      },
    ];

    return (
      <Card className={margin} title={"Details"}>
        <div className="grid grid-cols-12 gap-1 my-3 w-full">
          <FriendlyColumns data={payloadReview} columns={columns} keyPrefix={"review"} />
        </div>

        <Link to={DatabaseFrontendPath + "/" + payloadReview.parentEntityType + EntryPostfix + payloadReview.parentEntityId}>
          <Button className={"mr-3 ml-3 mb-3 mt-3 overflow-hidden rounded-b-md rounded-t-md"} onClick={() => {}}>
            Content
          </Button>
        </Link>
      </Card>
    );
  };

  const cardClass = "lg:w-1/2 w-full";

  return (
    <>
      {payloadReview ? (
        <SeoHeader pageTitle={"Review"} pageSectionTitle={payloadReview.title} description={removeMarkdownFormatting(payloadReview.text)} />
      ) : null}

      <Title>Review Entry</Title>

      {payloadReview ? (
        <QueueManageButtons 
          id={id} 
          frontendPath={ReviewFrontendPath} 
          entityType={"review"} 
          isDeleted={payloadReview.isDeleted}           
        />
      ) : null}

      <Loader isLoading={isLoading} />
      <div className="lg:flex">
        <div className={`${cardClass} mr-2`}>
          {linksCard()}
          {<HistoryCard payload={payloadReview} />}
        </div>
        <div className={`${cardClass} ${margin}`}>
          {<DeletionNoticeCard payload={payloadReview} />}
          {reviewText()}
          {reviewResponse()}
        </div>
      </div>
    </>
  );
}
