import React from "react";
import PropTypes from "prop-types";

export default function Title({ className, children, ...otherProps }) {
  return (
    <div
      {...otherProps}
      className={`font-black font-gameFont retro-text-shadow uppercase text-white 
        text-5xl max-sm:text-3xl mb-6 mt-4 decoration-8 underline decoration-accent  ${className || ""}`}
    >
      {children}
    </div>
  );
}

Title.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
};
