import React, { useEffect } from "react";
import markdown from "../md/about.md";
import PropTypes from "prop-types";

function AboutPage({ setRaw, setHeading }) {
  useEffect(() => {
    setRaw(markdown);
    setHeading("About");
  }, []);

  return <></>;
}

AboutPage.propTypes = {
  setRaw: PropTypes.func.isRequired,
  setHeading: PropTypes.func.isRequired,
};

export default AboutPage;