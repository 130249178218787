/* LIBS */
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";

/* CUSTOMS */
import { fetchPost } from "lib/fetch";
import Title from "components/title";
import Loader from "components/loader";
import { Link } from "react-router-dom";

/* CONSTANTS */
import {
  SearchBackendPostfix,
  AdminBackendPath,
  SessionBackendPostfix,
} from "constants/routing/backend";
import { Table } from "components/table";
import { AdminFrontendPath, EntryPostfix, UserFrontendPath } from "constants/routing/frontend";

export default function ActiveSessions() {
  const [payload, setPayload] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    hackRequest();
  }, []);

  const hackRequest = () => {
    setIsLoading(true);

    fetchPost(AdminBackendPath + SessionBackendPostfix + SearchBackendPostfix, null)
      .then((resp) => {
        setPayload(resp);
      })
      .catch((resp) => {
        toast.error(resp.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const headerColClass = "px-6 py-3 text-left tracking-wider";
  const rightText = "px-6 text-left break-words";

  return (
    <>
      <Title>Active Session</Title>
      <Loader isLoading={isLoading} />

      <div className="lg:grid lg:grid-cols-12 gap-3">
        <div className="col-span-12">
          <Table>
            <thead>
              <tr>
                <td className={`w-20 ${headerColClass}`}>Username</td>
                <td className={`w-20 ${headerColClass}`}>Session Count</td>
              </tr>
            </thead>
            <tbody>
              {payload && payload.users.map((c, i) => {
                return (
                  <tr className="ml-3" key={`activeSessions-${i}`}>
                    <td className={rightText}>
                      <Link to={AdminFrontendPath + UserFrontendPath + EntryPostfix + c.userId}>
                        {c.username}
                      </Link>
                    </td>
                    <td className={rightText}>{c.sessionCount}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </div>
      <Loader isLoading={isLoading} />
    </>
  );
}
