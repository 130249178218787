import React, { useEffect } from "react";
import markdown from "../md/faq.md";
import PropTypes from "prop-types";

function FaqPage({ setRaw, setHeading }) {
  useEffect(() => {
    setRaw(markdown);
    setHeading("FAQ");
  }, []);

  return <></>;
}

FaqPage.propTypes = {
  setRaw: PropTypes.func.isRequired,
  setHeading: PropTypes.func.isRequired,
};

export default FaqPage;