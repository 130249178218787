/** LIBS */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

/* CUSTOMS */
import SeoHeader from "components/seoHeader";
import Title from "components/title";
import Heading from "components/heading";
import { fetchPost } from "lib/fetch";
import Loader from "components/loader";
import Card from "components/card";
import Button from "components/button";

/* CONSTANTS */
import forumThread from "./data/subforums.json"; 
import { ForumFrontendPath } from "constants/routing/frontend";
import { AdminBackendPath, SearchBackendPostfix, SessionBackendPostfix } from "constants/routing/backend";

/* ICONS */
import ForumIcon1 from "assets/icon-forum-1.png";

export default function ForumHub() {
  const [isLoading, setIsLoading] = useState(false);
  const [payload, setPayload] = useState(null);

  useEffect(() => {
    hackRequest();
  }, []);

  const hackRequest = () => {
    setIsLoading(true);

    fetchPost(AdminBackendPath  + SessionBackendPostfix + SearchBackendPostfix, null)
      .then((resp) => {
        setPayload(resp);
      })
      .catch((resp) => {
        toast.error(resp.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div className="space-y-3 mb-3">
      <SeoHeader pageTitle={"Forum"} />
      <Title>Forum</Title>
      <Loader isLoading={isLoading} />

      <div className="flex justify-end">
        <Link to={"topic/search"}>
          <Button className={"rounded-lg"}>View Unread</Button>
        </Link>
      </div>


      {forumThread && Object.keys(forumThread).map((x, i) => {

        const subForums= forumThread[x];

        return (
          <Card key={`forum-${i}`} title={x} noMargin={true}>
            <table className="w-full">
              {Object.keys(subForums).map((subForumName, i) => {

                const forum = subForums[subForumName];

                const borderBottom = Object.keys(subForums).length -1 === i || "border-b-2 border-gray-400";
                
                return (
                  <tbody key={`subForum-${i}`} className="w-full">
                    <tr>
                      <td className={`w-16 ${borderBottom}`}>
                        <img src={ForumIcon1} className="pixelImage h-16 p-2" alt="" />
                      </td>
                      <td colSpan={2} className={`text-left p-2 w-auto ${borderBottom}`}>
                        <Heading>
                          <Link to={ForumFrontendPath +SearchBackendPostfix + "/"+ forum.tag}>
                            {subForumName}
                          </Link>
                        </Heading>
                        <div>
                          {forum.description}
                        </div>
                      </td>
                    </tr>
                  </tbody>
                );
              })}
            </table>
          </Card>
        );
      })}

      <Card title="Info Center" className="text-left">
        <Heading>Forum Stats</Heading>

        <Heading>Users Online</Heading>

        <div className="text-sm ml-2">
          <div>
            <b>Online:</b> {payload && payload.totalSessions} Active Sessions, {payload && payload.users.length} Users
          </div>
          <div>
          Users active in the past 24 hours:{" "}
            { payload && payload.users.map((x, i) => {
              return(
                <React.Fragment key={`userList-${i}`}>
                  <Link >{x.username}</Link>
                  { i < payload.users.length - 1 && ", "}
                </React.Fragment>
              );         
            })}
          </div>
        </div>
      </Card>
    </div>
  );
}
