import React, { useEffect } from "react";
import markdown from "../md/start.md";
import PropTypes from "prop-types";

function GettingStartedPage ({ setRaw, setHeading }) {
  useEffect(() => {
    setRaw(markdown);
    setHeading("Getting Started");
  }, []);
  
  return <></>;
}

GettingStartedPage.propTypes = {
  setRaw: PropTypes.func.isRequired,
  setHeading: PropTypes.func.isRequired,
};

export default GettingStartedPage;
