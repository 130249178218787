/** LIBS */
import React from "react";
import { Route, Routes } from "react-router-dom";

/* CUSTOMS */
import SeoHeader from "components/seoHeader";
import ForumHub from "./hub";
import ForumTopicSearch from "./forum";
import TopicCreate from "./topicCreate";
import TopicEntry from "./topic";
import ForumTopicAdvancedSearch from "./advancedSearch";

/* CONSTANTS */

export default function ForumListRoutes() {
  const hiddenLinks = [
    {
      pathPart: "",
      element: <ForumHub />,
    },
    {
      pathPart: "/search/:tag",
      element: <ForumTopicSearch />,
    },
    {
      pathPart: "/topic/:id",
      element: <TopicEntry />,
    },
    {
      pathPart: "/topic/create/:tag",
      element: <TopicCreate />,
    },
    {
      pathPart: "/topic/search",
      element: <ForumTopicAdvancedSearch />,
    },
  ];
  return (
    <>
      <SeoHeader 
        pageTitle={"Forum"} 
      />

      <Routes>
        {hiddenLinks.map(({ pathPart, element }, i) => {
          return (
            <Route
              key={`searchRoute-${i}`}
              path={pathPart}
              exact
              element={element}
            />
          );
        })}
      </Routes>
    </>
  );
}
