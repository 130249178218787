import React, { createContext, useState, useEffect } from "react";
import PropTypes from "prop-types";

// Create the context
export const MenuContext = createContext();

// Create a provider component
export const MenuProvider = ({ children }) => {
  const [isMenuExpanded, setIsMenuExpanded] = useState(() => {
    return localStorage.getItem("menuExpanded") === "true" || false;
  });

  useEffect(() => {
    localStorage.setItem("menuExpanded", isMenuExpanded);
  }, [isMenuExpanded]);

  const toggleMenu = () => {
    setIsMenuExpanded((prevTheme) => (prevTheme === true ? false : true));
  };

  return (
    <MenuContext.Provider value={{ isMenuExpanded, toggleMenu }}>
      {children}
    </MenuContext.Provider>
  );
};

// PropTypes for ThemeProvider
MenuProvider.propTypes = {
  children: PropTypes.node.isRequired,
};