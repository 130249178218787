/* LIBS */
import React, { createContext, useState, useEffect, useRef, useContext } from "react";
import PropTypes from "prop-types";
import { UserContext } from "../context/user";
import { defaultWebsocketObject } from "constants/models";

export function WebSocketContextProvider({ children, webSocketPath }) {
  const [webSocketMessages, setWebSocketMessages] = useState(defaultWebsocketObject);

  const { userContext } = useContext(UserContext);

  let ws = useRef(null);
  const intervalMs = 5 * 1000;

  useEffect(() => {
    initiateWSConnection();
    return () => {
      ws.close();
    };
  }, []);

  const initiateWSConnection = () => {
    ws = new WebSocket(webSocketPath);

    ws.onopen = () => {
      console.debug(`Websocket opened at "${webSocketPath}"`);
    };

    ws.onclose = () => {
      console.debug(
        `Websocket closed at "${webSocketPath}"; reconnecting in ${intervalMs}.`
      );

      setTimeout(initiateWSConnection, intervalMs);
    };

    ws.onmessage = (message) => {
      let json = JSON.parse(message.data);

      if (
        json.receiver === userContext.username ||
        json.receiver === "broadcast"
      ) {
        let temp = { ...webSocketMessages };

        if (!temp[json.datatype]) {
          console.debug(`Key ${json.datatype} doesn't exist in message object.`);
          temp[json.datatype] = [];
        }

        temp[json.datatype].push(json);
        setWebSocketMessages(temp);
      }
    };

    ws.onerror = () => {
      console.debug(`Websocket error: '${webSocketPath}'.`);
    };
  };

  const removeNotification = (key) => {
    let temp = { ...webSocketMessages };
    temp[key].shift();
    setWebSocketMessages(temp);
  };

  const websocketContext = {
    notifications: webSocketMessages,
    removeNotification,
  };

  return (
    <WebSocketContext.Provider value={websocketContext}>
      {children}
    </WebSocketContext.Provider>
  );
}

export const WebSocketContext = createContext({
  notifications: [],
});

WebSocketContextProvider.propTypes = {
  children: PropTypes.any.isRequired,
  webSocketPath: PropTypes.string.isRequired,
};
