import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";

export default function PopupBox({ pageTitle, pageSectionTitle, description }) {
  const displayDescription = description ? description : "Classic game mods and resources";
  const title = `${pageTitle}${pageSectionTitle ? ` - ${pageSectionTitle}` : ""}`;

  return (
    <Helmet>
      <meta charset="utf-8" />
      <title>{`RHDI - ${title}`}</title>
      <meta name="description" content={displayDescription} />
      <meta property="og:site_name" content="RHDI"/>
      <meta property="og:description" content={displayDescription} />
      <meta property="og:title" content={title} />
      <meta property="og:type" content="website" />
    </Helmet>
  );
}

PopupBox.propTypes = {
  pageTitle: PropTypes.string.isRequired,
  pageSectionTitle: PropTypes.string,
  description: PropTypes.string,
};
