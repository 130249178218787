/* LIBS */
import React, { useState } from "react";
import PropTypes from "prop-types";

const ImageHover = ({src, hoverSrc, alt, className, onClick, label}) => {
  const [isHovered, setIsHovered] = useState(false);

  const [isClicked, setIsClicked] = useState(false);

  const handleClick = () => {
    if (onClick) {
      onClick();
    }

    setIsClicked(true);

    setTimeout(() => {
      setIsClicked(false);
    }, 100);
  };

  return (
    <div className="flex flex-wrap flex-center items-center font-gameFont text-xs retro-text-outline-small"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onTouchStart={() => setIsHovered(true)}
      onTouchEnd={() => setIsHovered(false)}
      onFocus={() => setIsHovered(true)}
      onBlur={() => setIsHovered(false)}
      onClick={handleClick}
    >
      <div className="flex items-center justify-center">
        <div>
          <div
            className={`select-none transition-transform duration-100 transform 
        ${!isClicked || "-rotate-0"}
        ${isHovered & !isClicked ? "-rotate-6": null}
        `}
          >
            {isHovered || <img
              src={src}
              alt={alt}
              className={`${className}`}
            />}
            {!isHovered || <img
              src={hoverSrc}
              alt={alt}
              className={`${className}`}
            />}

          </div>
        </div>
        <div className={`truncate ${isHovered ? "text-accent": "text-white"}`}>{label}</div>
      </div>
    </div>
  );
};

ImageHover.propTypes = {
  src: PropTypes.any,
  hoverSrc: PropTypes.any,
  alt: PropTypes.string,
  className: PropTypes.string, 
  onClick: PropTypes.func, 
  label: PropTypes.string, 
};

export default ImageHover;