import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";

const UnsavedChangesWarning = ({ condition }) => {
  const conditionRef = useRef(condition);

  useEffect(() => {
    conditionRef.current = condition;
  }, [condition]);

  const friendlyMessage = "You have unsaved changes. Are you sure you want to leave?";

  // Handle the 'beforeunload' event when user tries to close the tab/window
  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (conditionRef.current !== undefined && conditionRef.current === false) {
        return;
      }

      e.preventDefault();
      e.returnValue = ""; // Required for modern browsers
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  // Handle the 'click' event for links that don't open in new tabs
  useEffect(() => {
    const handleLinkClick = (e) => {
      if (conditionRef.current !== undefined && conditionRef.current === false) {
        return;
      }

      const isNewTab = e.metaKey || e.ctrlKey || e.button === 1;

      if (isNewTab) {
        return;
      }

      if (!window.confirm(friendlyMessage)) {
        e.preventDefault(); // Prevent navigation if user cancels
      }
    };

    // Find all links that do not open in a new tab
    const links = document.querySelectorAll("a:not([target='_blank']), [role='link']:not([target='_blank'])");
    links.forEach((link) => link.addEventListener("click", handleLinkClick));

    return () => {
      links.forEach((link) => link.removeEventListener("click", handleLinkClick));
    };
  }, []);

  return <></>;
};

UnsavedChangesWarning.propTypes = {
  condition: PropTypes.bool,
};

export default UnsavedChangesWarning;
