import React, { useEffect } from "react";
import PropTypes from "prop-types";

import "./popup.css";

import { BiX as CancelIcon } from "react-icons/bi";

export default function PopupBox(props) {
  let { title, isOpen, onClose, zIndex, children, className, scrollOverflow } = props;

  if (!zIndex) {
    zIndex = 200;
  }

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
      return;
    } 

    document.body.style.overflow = "auto";
  }, [isOpen]);

  useEffect(() => {
    document.body.style.overflow = "auto";
  }, []);

  return (
    <div style={{zIndex: zIndex}} className={`${className} fixed inset-0 
    flex items-center justify-center bg-gray-900 bg-opacity-50 ${isOpen ? "" : "hidden"}`}
    onClick={onClose}
    >
      <div className="modalBody" onClick={(e) => e.stopPropagation()}>
        <div className="flex items-center justify-between bg-menuColor rounded-t-lg">
          <div />
          <h1 className="ml-12 text-white mt-3 mb-3 text-2xl font-black font-sans">
            {title}
          </h1>
          <button onClick={onClose} className="mr-3 w-10 h-10 rounded-full text-white">
            <CancelIcon className="ml-2 hover:opacity-50" size={25} />
          </button>
        </div>

        <div className={`p-6 ${scrollOverflow ? "max-h-[calc(100vh-120px)] overflow-y-auto": null} relative`}>{children}</div>
      </div>
    </div>
  );
}

PopupBox.propTypes = {
  title: PropTypes.string.isRequired,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  zIndex: PropTypes.number,
  children: PropTypes.any,
  className: PropTypes.string,
  scrollOverflow: PropTypes.bool,
};
