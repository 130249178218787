/* LIBS */
import React from "react";
import Button from "components/button";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Card from "components/card";
import Markdown from "react-markdown";

/* CUSTOMS */
import { FriendlyColumns } from "components/friendlyColumns";
import { removeMarkdownFormatting } from "lib/strings";
import { extractFromObjectWithPath } from "lib/extractFromObjectWithPath";

/* ICONS */
import { FaArrowRightToBracket as GotoIcon } from "react-icons/fa6";

export const FriendlyTable = ({ data, thumbnailKey, preambleKey, columns, titleKey, gotoIdPath, preambleNoTruncate }) => {
  const ImageRenderer = (imageUrl, linkToAsset) => {
    return (
      <Link key={thumbnailKey} className="text-sm underline flex justify-center" to={linkToAsset}>
        {!imageUrl || (
          <img key={imageUrl} /* Force re-render */ src={imageUrl} loading="lazy" className="lg:w-full max-lg:w-1/2 object-cover" alt="" />
        )}
      </Link>
    );
  };

  return (
    <div>
      {data &&
        data.map((d, dataI) => {
          const thumbnailUrl = extractFromObjectWithPath(thumbnailKey, d);
          const isArrayOfImages = Array.isArray(thumbnailUrl);
          const isThumbnailOnSide = !isArrayOfImages;
          const linkToAsset = gotoIdPath + d.id;

          const preambleText = d[preambleKey];
          return (
            <Card key={`friendlyTable-${dataI}`} className="m-3" title={d[titleKey]} to={linkToAsset}>
              <div className="grid grid-cols-12 gap-1 w-full">
                {!isThumbnailOnSide || (
                  <div className="col-span-12 lg:col-span-2 flex items-center justify-center">
                    {ImageRenderer(thumbnailUrl, linkToAsset)}
                  </div>
                )}

                <div className={`${isThumbnailOnSide ? "lg:col-span-10" : "lg:col-span-12"} col-span-12 p-2`}>
                  {!d[preambleKey] || (
                    <div className={`text-contrastChangeText text-left ${preambleNoTruncate || "truncate-multiline"}`}>
                      {!preambleNoTruncate ? removeMarkdownFormatting(preambleText) : <Markdown>{preambleText}</Markdown>}
                    </div>
                  )}
                  <div className="grid grid-cols-12 gap-1 mb-3 mt-3 w-full">
                    <FriendlyColumns data={d} columns={columns} keyPrefix={dataI} />
                  </div>
                </div>
              </div>

              <div className="flex justify-end">
                <Link className="text-sm" to={linkToAsset}>
                  <Button className="rounded-lg">
                    <div className="flex items-center justify-center">
                      <GotoIcon className="mr-2" /> Goto
                    </div>
                  </Button>
                </Link>
              </div>
            </Card>
          );
        })}
    </div>
  );
};

FriendlyTable.propTypes = {
  data: PropTypes.any,

  titleKey: PropTypes.string,
  thumbnailKey: PropTypes.string,
  preambleKey: PropTypes.string,
  gotoIdPath: PropTypes.string,
  columns: PropTypes.array,

  preambleNoTruncate: PropTypes.bool,
};
