import React from "react";
import PropTypes from "prop-types";

const generateColorFromUsername = (username) => {
  // Simple hash function to generate a number from the username
  const hashCode = (str) => {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = (hash << 5) - hash + str.charCodeAt(i);
      hash |= 0; // Convert to 32bit integer
    }
    return hash;
  };

  const hash = hashCode(username);
  const color = `hsl(${hash % 360}, 70%, 70%)`;
  return color;
};

const getInitials = (username) => {
  const names = username.split(" ");
  if (names.length > 1) {
    return names[0][0] + names[names.length - 1][0];
  }
  return username.substring(0, 2).toUpperCase();
};

const Avatar = ({ username, size = 10 }) => {
  const color = generateColorFromUsername(username);
  const initials = getInitials(username);

  return (
    <div
      className="flex items-center justify-center text-white font-bold rounded-full"
      style={{
        backgroundColor: color,
        width: `${size}rem`,
        height: `${size}rem`,
        fontSize: `${size / 2}rem`,
      }}
    >
      {initials}
    </div>
  );
};

Avatar.propTypes = {
  username: PropTypes.string.isRequired,
  size: PropTypes.number,
};

export default Avatar;