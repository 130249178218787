import React from "react";
import PropTypes from "prop-types";

export default function Paper({ className, children, ...otherProps }) {

  return (
    <div className={`${className} shadow-lg`}>
      <div
        {...otherProps}
        className={"border-borderColor border-2 rounded-lg px-6 py-4 shadow-lg bg-cardBg text-textWithContrastLightBg"}
      >
        {children}
      </div>
    </div>
  );
}

Paper.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any.isRequired,
};
