/* LIBS */
import React from "react";
import PropTypes from "prop-types";

/* CUSTOMS */
import Card from "components/card";
import AdvancedFilter from "components/advancedFilter";
import TextBox from "components/textBox";
import Button from "components/button";
import Details from "components/details";

/* ICONS */
import { FaMagnifyingGlass as SearchIcon } from "react-icons/fa6";

export default function AdvancedFilterCard({filters, setFilters, setQueryString, searchUri, onClick, metadata}) {

  const handleKeyDown = (e) => {
    if (e === null){
      return;
    }
    
    if (e.key !== "Enter") {
      return;
    }

    e.preventDefault(); // Prevent the default form submission
    onClick();
  };

  return (
    <Card className="m-2" title={"Search Filters"}>
      <div className="text-start ml-3">
        <div className="grid grid-cols-3">
          <TextBox 
            id={"advancedFilter"} 
            className="col-span-2 w-full" 
            placeholder={"Simple Query"} 
            onChange={setQueryString} 
            onKeyDown={handleKeyDown}
          />
          <div className="flex items-center">
            <Button 
              onClick={onClick} 
              className="col-span-1 m-3 rounded-lg">
              <SearchIcon />
            </Button>
          </div>
        </div>
      </div>
      <Details>
        <AdvancedFilter
          filters={filters}
          setFilters={setFilters}
          searchUri={searchUri}
          metadata={metadata}
        />
      </Details>
    </Card>
  );
}

AdvancedFilterCard.propTypes = {
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      operator: PropTypes.string,
      field: PropTypes.string.isRequired,
      value: PropTypes.string,
    })
  ).isRequired,
  setFilters: PropTypes.func,
  setQueryString: PropTypes.func,
  searchUri: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  metadata: PropTypes.object,
};
