import Platform from "./platform";

// @ts-check
export default class N64Platform extends Platform {
  /**
   *  Returns a string value representing the ROM layout, or null if layout can't be determined.
   *  See romLayout.
   *  @param {Uint8Array} rom
   */
  getRomLayout(rom) {
    var data = Array.from(rom);

    if (equalsAt(data, n64Identifier, 0)) {
      return romLayout.bigEndian;
    }

    if (equalsAt(data, n64Identifier_Byteswapped, 0)) {
      return romLayout.byteSwapped;
    }
    if (equalsAt(data, n64Identifier_Wordswapped, 0)) {
      return romLayout.wordSwapped;
    }
    if (equalsAt(data, n64Identifier_LittleEndian, 0)) {
      return romLayout.n64Identifier_LittleEndian;
    }

    return null;
  }

  /**
   * Converts the rom to a normalized form.
   * @returns {object} Information about the conversion
   */
  normalize() {
    var chunkConverter;

    const layout = this.getRomLayout(this.rom);
    const bytesOut = Array.from(this.rom);

    switch (layout) {
    case romLayout.bigEndian:
      return { message: romFormatNames[romLayout.bigEndian], rom: bytesOut };
    case romLayout.byteSwapped:
      chunkConverter = chunkToBin_byteswapped;
      break;
    case romLayout.wordSwapped:
      chunkConverter = chunkToBin_wordswapped;
      break;
    case romLayout.littleEndian:
      chunkConverter = chunkToBin_littleEndian;
      break;
    default:
      return {message: "Invalid", rom: bytesOut};
    }

    chunkConverter(this.rom, bytesOut);

    return {message: romFormatNames[layout], rom: bytesOut};
  }
}

////////////////////////////////////////////////////////////////////////

/** First four bytes of a normal (big-endian) N64 ROM */
const n64Identifier = [0x80, 0x37, 0x12, 0x40];

/** First four bytes of a byte-swapped N64 ROM */
const n64Identifier_Byteswapped = [0x37, 0x80, 0x40, 0x12];

/** First four bytes of a word-swapped N64 ROM */
const n64Identifier_Wordswapped = [0x12, 0x40, 0x80, 0x37];

/** First four bytes of a little-endian  N64 ROM */
const n64Identifier_LittleEndian = [0x40, 0x12, 0x37, 0x80];

/** Enum listing known ROM layouts */
var romLayout = {
  bigEndian: "bigEndian",
  littleEndian: "littleEndian",
  byteSwapped: "byteSwapped",
  wordSwapped: "wordSwapped",
};

/** Key/value pairs mapping known ROM layouts to display-friendly format names */
var romFormatNames = {
  bigEndian: "N64 ROM (big-endian)",
  littleEndian: "N64 ROM (little-endian)",
  byteSwapped: "N64 ROM (byte-swapped)",
  wordSwapped: "N64 ROM (word-swapped)",
};

/**
 * Converts a chunk of byte-swapped ROM to big-endian ROM. Data
 * must be 64-bit aligned. Input and output arrays can be the same
 * array. Output array must have adequate memory allocated.
 * @param {Uint8Array} bytesIn
 * @param {Uint8Array} bytesOut
 */
function chunkToBin_byteswapped(bytesIn, bytesOut) {
  // Byte-swap in place
  var len = bytesIn.length - 1; // accounting for odd number of bytes
  for (var i = 0; i < len; i += 2) {
    var swap = bytesIn[i + 1];
    bytesOut[i + 1] = bytesIn[i];
    bytesOut[i] = swap;
  }
}

/**
 * Converts a chunk of word-swapped ROM to big-endian ROM. Data
 * must be 64-bit aligned. Input and output arrays can be the same
 * array. Output array must have adequate memory allocated.
 * @param {Uint8Array} bytesIn
 * @param {Uint8Array} bytesOut
 */
function chunkToBin_wordswapped(bytesIn, bytesOut) {
  // Byte-swap in place
  var len = bytesIn.length - 3; // accounting for odd number of bytes
  for (var i = 0; i < len; i += 4) {
    var swap = bytesIn[i + 2];
    bytesOut[i + 2] = bytesIn[i];
    bytesOut[i] = swap;

    swap = bytesIn[i + 3];
    bytesOut[i + 3] = bytesIn[i + 1];
    bytesOut[i + 1] = swap;
  }
}

/**
 * Converts a chunk of little-endian ROM to big-endian ROM. Data
 * must be 64-bit aligned. Input and output arrays can be the same
 * array. Output array must have adequate memory allocated.
 * @param {Uint8Array} bytesIn
 * @param {Uint8Array} bytesOut
 */
function chunkToBin_littleEndian(bytesIn, bytesOut) {
  // Byte-swap in place
  var len = bytesIn.length - 3; // accounting for odd number of bytes
  for (var i = 0; i < len; i += 4) {
    var swap = bytesIn[i + 3];
    bytesOut[i + 3] = bytesIn[i];
    bytesOut[i] = swap;

    swap = bytesIn[i + 2];
    bytesOut[i + 2] = bytesIn[i + 1];
    bytesOut[i + 1] = swap;
  }
}
/**
 * Returns a boolean indicating whether the ROM is byteswapped, or null
 * if the ROM can not be determined to be an N64 ROM.
 * @param {Uint8Array} rom
 * @returns {boolean} A boolean or null.
 */

/**
 *
 * @param {ArrayLike<number>} data
 * @param {ArrayLike<number>} comparison
 * @param {number} dataOffset
 */
function equalsAt(data, comparison, dataOffset) {
  if (data.length < dataOffset + comparison.length) {
    return false;
  }

  for (var i = 0; i < comparison.length; i++)
    if (data[i + dataOffset] !== comparison[i]) {
      return false;
    }

  return true;
}
