/** LIBS */
import React, { useState, useEffect, useRef, useContext } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { sleepSeconds } from "lib/sleep";

/* CUSTOMS */
import SeoHeader from "components/seoHeader";
import Title from "components/title";
import { Link } from "react-router-dom";
import defaultAvatar from "../../assets/defaultAvatar.png";
import Paginator from "components/paginator";
import Markdown from "react-markdown";
import { fetchPost } from "lib/fetch";
import { toast } from "react-toastify";
import { requestFormatFilter } from "lib/elastic";
import Loader from "components/loader";
import PopupBox from "components/popupBox";
import Card from "components/card";
import { FriendlyColumns } from "components/friendlyColumns";

/* CONSTANTS */
import { pageSize } from "constants/models";
import { PermissionAMod } from "constants/permissions";
import { CreatePostfix, ForumFrontendPath } from "constants/routing/frontend";
import { DeleteBackendPostfix, ForumBackendPath, GetBackendPostfix, SearchBackendPostfix } from "constants/routing/backend";

/* ICONS */
import Paper from "components/paper";
import Button from "components/button";
import Time from "components/time";
import CommentBox from "./commentBox";

/* SERVICES */
import { UserContext } from "context/user";
import InvisibleScroll from "components/invisibleScroll";

export default function TopicEntry() {
  const [page, setPage] = useState(0);
  const [payloadTopic, setPayloadTopic] = useState(null);
  const [payload, setPayload] = useState(null);
  const [sortField, ] = useState("updatedDate");
  const [sortDirection, ] = useState("desc");
  const [isLoading, setIsLoading] = useState(false);

  const [postText, setPostText] = useState("");

  const [modalOpen, setModalOpen] = useState(false);
  const [modalAction, setModalAction] = useState(() => {});
  const [modalText, setModalText] = useState("");

  const navigate = useNavigate();
  const postBoxRef = useRef(null);

  const { id } = useParams();
  const { userContext } = useContext(UserContext);
  const { username , permissions } = userContext;

  useEffect(() => {
    topicGetRequest();
  }, []);

  useEffect(() => {
    postsSearchRequest();
  }, [page, sortField, sortDirection ]);

  const topicGetRequest = () => {
    setIsLoading(true);

    const body = {
      id: id,
    };

    fetchPost(ForumBackendPath + "/topic" + GetBackendPostfix, body)
      .then((resp) => {
        setPayloadTopic(resp);
      })
      .catch((resp) => {
        toast.error(resp.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const postsSearchRequest = async () => {
    setIsLoading(true);

    let matchFilter = requestFormatFilter([
      {
        field: "topicId",
        operator: "must",
        value: id,
      },
    ]);

    const body = {
      page: page,
      sortField: sortField,
      sortDirection: sortDirection,
      filters: matchFilter,
    };

    fetchPost(ForumBackendPath + "/post" + SearchBackendPostfix, body)
      .then((resp) => {
        setPayload(resp);
      })
      .catch((resp) => {
        toast.error(resp.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const createPost = () => {
    setIsLoading(true);

    const body = {
      topicId: id,
      text: postText
    };

    fetchPost(ForumBackendPath + "/post" + CreatePostfix, body)
      .then(async (resp) => {
        await sleepSeconds(1); // Sleep to wait for ElasticSearch to index
        toast.success("Created post.");

        await postsSearchRequest();
        payloadTopic.posts += 1;

        if (postBoxRef.current) {
          postBoxRef.current.clear();
        }
      })
      .catch((resp) => {
        toast.error(resp.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const deleteTopicRequest = () => {
    setIsLoading(true);

    const body = {
      id: id,
    };

    fetchPost(ForumBackendPath + "/topic" + DeleteBackendPostfix, body)
      .then((resp) => {
        navigate(ForumFrontendPath);
      })
      .catch((resp) => {
        toast.error(resp.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const deletePostRequest = (postId) => {
    setIsLoading(true);

    const body = {
      id: postId,
    };

    fetchPost(ForumBackendPath  + "/post" + DeleteBackendPostfix, body)
      .then(async(resp) => {
        await sleepSeconds(1); // Sleep to wait for ElasticSearch to index
        toast.success("Deleted post.");

        await postsSearchRequest();
        payloadTopic.posts -= 1;

      })
      .catch((resp) => {
        toast.error(resp.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };


  const paginationElement = () => {
    if (!payload || !payload.entries){
      return;
    }

    return (
      <Paginator
        page={page}
        setPage={setPage}
        currentPageSize={payload.entries && payload.entries.length}
        pageWindowSize={pageSize}
        totalEntries={payload && payload.total}
      />
    );
  };

  const headings = [
    {
      label: "Created By",
      field: "createdBy",
      idField: "createdById",
      width: "col-span-4 lg:col-span-2",
      dataType: "link",
      to: (data) => {
        return "TODO"; //`/database/${data.entityType}/entry/`;
      },
    },
    {
      label: "Creation Time",
      field: "createdDate",
      width: "col-span-4 lg:col-span-2",
      dataType: "time",
    },
    {
      label: "Views",
      field: "views",
      width: "col-span-4 lg:col-span-2",
      dataType: "write",
    },
    {
      label: "Posts",
      field: "posts",
      width: "col-span-4 lg:col-span-2",
      dataType: "write",
    },
    {
      label: "Tags",
      field: "tags",
      width: "col-start-1 col-span-6",
      dataType: "pills",
    },
    {
      label: "Sub-Tags",
      field: "subTags",
      width: "col-span-6",
      dataType: "pills",
    },
  ];

  const canDelete = permissions.includes(PermissionAMod);
  const canEditTopic = permissions.includes(PermissionAMod) || (payloadTopic && payloadTopic.username === username);
  const canPost = username !== "" && username !== undefined && username !== null;

  const gameMultiSelectRef = useRef(null);

  if (!payloadTopic) {
    return;
  }

  return (
    <>
      <SeoHeader pageTitle={"Forum"} />
      <Title>Topic</Title>

      <Loader isLoading={isLoading} />

      <PopupBox title={"Warning"} onClose={() => setModalOpen(false)} isOpen={modalOpen}>
        <div className="mb-3">{modalText}</div>
        <div className="flex items-center justify-end space-x-3">
          <Button className="rounded-lg" onClick={() => setModalOpen(false)}>Cancel</Button>
          <Button className="rounded-lg" onClick={() => {
            setModalOpen(false);
            modalAction();
          }} secondary={true}>Confirm</Button>
        </div>
      </PopupBox>

      <Card title={payloadTopic.title} className={"mb-3"}>
        <div className="grid grid-cols-12 gap-1 my-3 w-full">
          <FriendlyColumns data={payloadTopic} columns={headings} keyPrefix={""} />
        </div>
        <div className="flex items-center justify-end">
          { canEditTopic && <Button className="rounded-lg mr-2">Edit</Button>}
          {canDelete && <Button 
            secondary={true} 
            className="rounded-lg"
            onClick={()=> {
              setModalText("Are you sure you want to delete this topic? This action cannot be undone.");
              setModalAction(() => deleteTopicRequest);
              setModalOpen(true);
            }}
          >
            Delete
          </Button>}
        </div>
      </Card>

      <div className="flex items-center justify-end">{paginationElement()}</div>

      {payload && payload.entries &&
        payload.entries.map((x, i) => {
          return (
            <Paper key={`post-${i}`} className={"my-2"}>
              <div className="grid grid-cols-12">
                <div className="max-xl:col-span-2 col-span-1">
                  <div className="text-left">
                    <Link className="font-bold">{x.createdBy}</Link>

                    <img src={defaultAvatar} alt="" />

                    <div className="text-sm">Sr. Member</div>
                    <div className="text-sm">Posts: 403</div>
                  </div>
                </div>
                <div className="max-xl:col-span-10 col-span-11 text-left">
                  <div className="flex justify-between">
                    <div className="text-xs mb-1 flex justify-start">
                      Posted <Time className="ml-1" time={x.createdDate} fromNow />
                    </div>
                    <div className="text-xs mb-1">
                      {x.updatedDate === 0 || (
                        <div className="text-xs mb-1 flex justify-start italic">
                          Last Edit: <Time className="mr-1 ml-1" time={x.updatedDate} fromNow /> by {x.updatedByUser}
                        </div>
                      )}
                    </div>
                  </div>

                  <hr className="mb-2" />
                  <Markdown className="break-words">{x.text}</Markdown>
                </div>
              </div>
              <div className="flex justify-end">
                <div className="space-x-2">
                  { canPost && (<Button 
                    onClick={
                      ()=> {
                        gameMultiSelectRef.current.jumpToSection();
                      }
                    }
                    className="rounded-lg"
                  >Quote</Button>)}
                  <Button className="rounded-lg">Permalink</Button>
                  {canDelete && <Button 
                    className="rounded-lg" 
                    onClick={
                      () => {
                        setModalText("Are you sure you want to delete this post? This action cannot be undone.");
                        setModalAction(() => () =>  deletePostRequest(x.id));
                        setModalOpen(true);
                      }
                    }
                    secondary={true}>Delete</Button>}
                </div>
              </div>
            </Paper>
          );
        })}

      <InvisibleScroll ref={gameMultiSelectRef}/>

      {canPost && (<Card title="Create post" className={"mb-2"}>
        <CommentBox
          ref={postBoxRef}
          className={"my-2"}
          postText={postText} 
          setPostText={setPostText} 
          title="Add Post"
        />

        <div className="flex justify-end">
          <Button className="rounded-lg" onClick={createPost}>Post</Button>
        </div>
      </Card>)}
    </>
  );
}
