/* CUSTOMS */
import React from "react";
import { HackSubmission, NewsSubmission } from "./pages";

/* ICONS */
import { FaWrench } from "react-icons/fa";
import { LuLanguages } from "react-icons/lu";
import { FaToolbox } from "react-icons/fa";
import { IoDocumentTextOutline } from "react-icons/io5";
import { IoNewspaperOutline } from "react-icons/io5";

/* CONSTANTS */
import {
  SubmissionFrontendPath,
  ContentFrontendPath,
  NewsFrontendPath,
} from "constants/routing/frontend";
/*import ImageHover from "components/imageHover";*/

/* ICONS */
/*import NewsIcon1 from "assets/icon-news-1.png";
import NewsIcon2 from "assets/icon-news-2.png";

import HackIcon1 from "assets/icon-hack-1.png";
import HackIcon2 from "assets/icon-hack-2.png";

import LanguageIcon1 from "assets/icon-language-1.png";
import LanguageIcon2 from "assets/icon-language-2.png";*/

const SubmissionList = [
  {
    text: "Hack",
    path: SubmissionFrontendPath + ContentFrontendPath,
    pathPart: ContentFrontendPath + "/:queueId?",
    icon: <FaWrench className="w-full text-white" size={150} />, //<ImageHover src={HackIcon1} hoverSrc={HackIcon2} />,
    element: <HackSubmission />,
    showSharingWarning: true,
  },
  {
    text: "Translation",
    path: SubmissionFrontendPath + ContentFrontendPath,
    pathPart: ContentFrontendPath + "/:queueId?",
    icon: <LuLanguages className="w-full text-white" size={150} />, //<ImageHover src={LanguageIcon1} hoverSrc={LanguageIcon2} />,
    element: <HackSubmission />,
    showSharingWarning: true,
  },
  {
    text: "Utility",
    path: SubmissionFrontendPath + ContentFrontendPath,
    pathPart: ContentFrontendPath + "/:queueId?",
    icon: <FaToolbox className="w-full text-white" size={150} />,
    element: <HackSubmission />,
    showSharingWarning: true,
  },
  {
    text: "Document",
    path: SubmissionFrontendPath + ContentFrontendPath,
    pathPart: ContentFrontendPath + "/:queueId?",
    icon: <IoDocumentTextOutline className="w-full text-white" size={150} />,
    element: <HackSubmission />,
    showSharingWarning: true,
  },
  {
    text: "News",
    path: SubmissionFrontendPath + NewsFrontendPath,
    pathPart: NewsFrontendPath + "/:queueId?",
    icon: <IoNewspaperOutline className="w-full text-white" size={150} />, //<ImageHover src={NewsIcon1} hoverSrc={NewsIcon2} />,
    element: <NewsSubmission />,
    showSharingWarning: false,
  },
];

export default SubmissionList;
