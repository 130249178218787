/** LIBS */
import React, { useState, useContext, useEffect } from "react";
import { toast } from "react-toastify";

/* CUSTOMS */
import SeoHeader from "components/seoHeader";
import { fetchPost } from "lib/fetch";
import Loader from "components/loader";
import { useNavigate } from "react-router-dom";

/* CONSTANTS */
import { LogoutBackendPath } from "constants/routing/backend";
import { UserContext } from "context/user";
import { LoginFrontendPath, UserFrontendPath } from "constants/routing/frontend";

export default function Logout() {
  const [isLoading, setIsLoading] = useState(false);

  const { setUserContext } = useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    setIsLoading(true);

    fetchPost(LogoutBackendPath, null)
      .then(() => {
        setUserContext({ permissions: [], username: null, userId: null });
        toast.success("Logged out");

        /*
        Overwrite the previous history entry (which would be the logout endpoint)
        This allows the user to navigate to the previous page they navigated,
        and not continuously logging out.
        */
        navigate(UserFrontendPath + LoginFrontendPath, { replace: true } );
      })
      .catch((resp) => {
        toast.error(resp.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  return (
    <>
      <SeoHeader pageTitle={"Logout"} />
      <Loader isLoading={isLoading} />
    </>
  );
}
