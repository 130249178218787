export const extractFromObjectWithPath = (path, obj) => {
  const pathFull = path.split(".");
  let extractedValues = [];

  const extractFromObjectWithPathRecursive = (path, o) => {

    let s;

    if (Array.isArray(path)) {
      s = path[0];
    } else {
      s = path;
    }

    // If path is empty, assume we are reading an object of values.
    if (s === undefined || s === null) {
      extractedValues = [...extractedValues, o];
      return;
    }

    const objVal = o[s];

    if (objVal === null || objVal === undefined){
      return null;
    }

    switch (typeof objVal) {
    case "object":
      if (Array.isArray(objVal)) {
        const isArrayOfObjects = objVal.every(item => typeof item === "object" && item !== null);
        const isArrayOfStrings = objVal.every(item => typeof item === "string");
  
        if (isArrayOfObjects) {
          extractedValues = objVal;
          return extractedValues;

        } else if (isArrayOfStrings) {
          extractedValues = objVal.join(", ");
        } else {
          throw new Error("The array contains mixed types.");
        }
      } else {
        extractedValues = [...extractedValues, objVal[path[path.length - 1]]];
      }
      break;
    case "string":
    case "number":
    case "boolean":
      extractedValues = [...extractedValues, objVal];
      break;
    default:
      extractFromObjectWithPathRecursive(path.slice(1), objVal);
      break;
    }
  };

  extractFromObjectWithPathRecursive(pathFull, obj);

  if (Array.isArray(extractedValues) && extractedValues.length === 1) {
    const isArrayOfObjects = extractedValues.every(item => typeof item === "object" && item !== null);

    if (!isArrayOfObjects){
      extractedValues = extractedValues[0];
    }
  }

  return extractedValues;
};
