/* LIBS */
import React, { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";

/* CUSTOMS */
import Paginator from "components/paginator";
import { pageSize } from "constants/models";
import { fetchPost } from "lib/fetch";
import Title from "components/title";
import { SortableTable } from "components/sortableTable";
import Loader from "components/loader";
import AdvancedFilterCard from "components/advancedFilterCard";
import InvisibleScroll from "components/invisibleScroll";

/* CONSTANTS */
import {
  AuthorBackendPath,
  SearchBackendPostfix,
  MetadataBackendPostfix,
} from "constants/routing/backend";

import {
  DatabaseFrontendPath,
  AuthorFrontendPath,
} from "constants/routing/frontend";
import { requestFormatFilter } from "lib/elastic";

export default function AuthorGridView() {
  const [payload, setPayload] = useState(null);
  const [metadata, setMetadata] = useState(null);
  const [page, setPage] = useState(0);
  const [sortField, setSortField] = useState("title");
  const [sortDirection, setSortDirection] = useState("asc");
  const [isLoading, setIsLoading] = useState(false);
  const [filters, setFilters] = useState([]);
  const [queryString, setQueryString] = useState("");

  const columns = [
    {
      label: "Title",
      idField: "id",
      field: "title",
      width: "w-20",
      dataType: "link",
      to: `${DatabaseFrontendPath}${AuthorFrontendPath}/entry/`,
    },
    {
      label: "Is Group",
      field: "isGroup",
      width: "w-20",
      dataType: "write",
    },
    {
      label: "Aliases",
      field: "aliases",
      width: "w-20",
      dataType: "write",
    },
    {
      label: "Tags",
      field: "tags",
      width: "w-20 max-md:hidden",
      dataType: "write",
    },
  ];

  useEffect(() => {
    fetchPost(AuthorBackendPath + MetadataBackendPostfix, null)
      .then((resp) => {
        setMetadata(resp);
      })
      .catch((resp) => {
        toast.error(resp.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    setPage(0);
  }, [sortField, sortDirection, filters]);

  useEffect(() => {
    translationRequest();
  }, [page, sortField, sortDirection, filters]);

  const translationRequest = () => {
    setIsLoading(true);

    let matchFilter = requestFormatFilter(filters);

    const body = {
      page: page,
      sortField: sortField,
      sortDirection: sortDirection,
      filters: matchFilter,
      queryString: queryString
    };

    fetchPost(AuthorBackendPath + SearchBackendPostfix, body)
      .then((resp) => {
        setPayload(resp);
      })
      .catch((resp) => {
        toast.error(resp.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const gameMultiSelectRef = useRef(null);

  const paginationWrapper = (autoScroll, allowInputChange) => {
    if (!payload) {
      return;
    }

    const onChange = () => {
      if (autoScroll) {
        gameMultiSelectRef.current.jumpToSection();
      }
    };

    return (
      <Paginator
        page={page}
        setPage={setPage}
        currentPageSize={payload.entries && payload.entries.length}
        pageWindowSize={pageSize}
        totalEntries={payload && payload.total}
        allowInputChange={allowInputChange}
        onChange={onChange}
      />
    );
  };

  return (
    <>
      <Loader isLoading={isLoading} />
      <Title>Authors</Title>

      <div className="lg:grid lg:grid-cols-12 gap-3">
        <div className="col-span-4">

          <AdvancedFilterCard
            filters={filters}
            setFilters={setFilters}
            setQueryString={setQueryString}
            searchUri={AuthorBackendPath}
            onClick={translationRequest}
            metadata={metadata}
          />
        </div>
        <div className="col-span-8">
          <div className="flex items-center justify-end">
            {paginationWrapper(false, true)}
          </div>

          <InvisibleScroll ref={gameMultiSelectRef} />

          <SortableTable
            data={payload && payload.entries}
            metadataColumn={metadata && metadata.columns}
            columns={columns}
            setSortField={setSortField}
            setSortDirection={setSortDirection}
            sortField={sortField}
            sortDirection={sortDirection}
          />
          <div className="flex items-center justify-end">
            {paginationWrapper(true, false)}
          </div>
        </div>
      </div>
    </>
  );
}
