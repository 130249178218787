import React, { useContext } from "react";
import { ThemeContext } from "context/themeProvider";
import PropTypes from "prop-types";
import { ClipLoader as Loader } from "react-spinners";
import { FaCheckCircle as SuccessIcon } from "react-icons/fa";
import { HiXCircle as FailureIcon } from "react-icons/hi";

export default function InlineLoader({ isSuccess, isLoading }) {
  const { theme } = useContext(ThemeContext);

  return (
    <>
      <div className="flex items-center justify-center z-50 ">
        {isLoading && <Loader color={theme === "dark" ? "white" : "var(--menuColor)"} />}
        {!isLoading && isSuccess && <SuccessIcon className="text-green-500" size={24} />}
        {!isLoading && !isSuccess && <FailureIcon className="text-red-500" size={29} />}
      </div>
    </>
  );
}

InlineLoader.propTypes = {
  isSuccess: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
};
