/* LIBS */
import React, { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";

/* CUSTOMS */
import Paginator from "components/paginator";
import { pageSize } from "constants/models";
import { fetchPost } from "lib/fetch";
import Title from "components/title";
import Loader from "components/loader";
import { FriendlyFilterCard } from "components/friendlyFilterCard";
import InvisibleScroll from "components/invisibleScroll";

/* CONSTANTS */
import { FieldValuesBackendPostfix, 
  FriendlyBackendPostfix, MetadataBackendPostfix, NewsBackendPath, SearchBackendPostfix } from "constants/routing/backend";
import { requestFormatFilter } from "lib/elastic";
import { ContentFrontendPath, DatabaseFrontendPath, EntryPostfix } from "constants/routing/frontend";
import Card from "components/card";
import Markdown from "react-markdown";
import Time from "components/time";
import Button from "components/button";
import { Link } from "react-router-dom";

/* ICONS */
import { FaArrowRightToBracket as GotoIcon } from "react-icons/fa6";
import Heading from "components/heading";
import Tags from "pages/database/tags";

export default function NewsEntry() {
  const [payload, setPayload] = useState(null);
  const [metadata, setMetadata] = useState(null);
  const [sortField] = useState("createdDate");
  const [sortDirection] = useState("desc");
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [filters, setFilters] = useState([]);

  const [queryString, setQueryString] = useState("");

  useEffect(() => {
    fetchPost(NewsBackendPath + FriendlyBackendPostfix + MetadataBackendPostfix, null)
      .then((resp) => {
        setMetadata(resp);

        const temp = Array(Object.keys(resp.filters).length).fill(null);

        setFilters(temp);
      })
      .catch((resp) => {
        toast.error(resp.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    setPage(0);
  }, [sortField, sortDirection, filters]);

  useEffect(() => {
    hackRequest();
  }, [page, sortField, sortDirection, filters]);

  const hackRequest = () => {
    if (!metadata) {
      return;
    }

    setIsLoading(true);

    const body = {
      page: page,
      sortField: sortField,
      sortDirection: sortDirection,
      filters: requestFormatFilter(filters),
      queryString: queryString,
    };

    fetchPost(NewsBackendPath + FriendlyBackendPostfix + SearchBackendPostfix, body)
      .then((resp) => {
        setPayload(resp);
      })
      .catch((resp) => {
        toast.error(resp.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const gameMultiSelectRef = useRef(null);

  const paginationWrapper = (autoScroll, allowInputChange) => {
    if (!payload) {
      return;
    }

    const onChange = () => {
      if (autoScroll) {
        gameMultiSelectRef.current.jumpToSection();
      }
    };

    return (
      <Paginator
        page={page}
        setPage={setPage}
        currentPageSize={payload.entries && payload.entries.length}
        pageWindowSize={pageSize}
        totalEntries={payload && payload.total}
        allowInputChange={allowInputChange}
        onChange={onChange}
      />
    );
  };

  const relatedUrl = (x) => {
    if (!x.relatedUrl) {
      return;
    }

    return (
      <>
        <div className="text-left">
          <Heading>Related Urls</Heading>
          <div>
            {x.relatedUrl &&
              x.relatedUrl.map((x, i) => {
                return (
                  <li key={`bullet-${x.id}-${i}`}>
                    <a href={x} target="_blank" rel="noreferrer">
                      {x}
                    </a>
                  </li>
                );
              })}
          </div>
        </div>
      </>
    );
  };

  const content = (item) => {
    if (!item.content) {
      return;
    }

    return (
      <>
        {item.content.map((x, i) => {
          return (
            <Link
              key={`bullet-${x.contentId}-${i}`}
              className="text-sm"
              to={DatabaseFrontendPath + ContentFrontendPath + EntryPostfix + x.contentId}
              title={x.contentTitle}
            >
              <Button className="rounded-lg m-2">
                <div className="flex items-center justify-center">
                  <GotoIcon className="mr-2" /> Content
                </div>
              </Button>
            </Link>
          );
        })}
      </>
    );
  };

  const images = (x) => {
    if (!x || !x.imagesUrl) {
      return;
    }

    return (
      <div className="flex justify-center flex-wrap">
        {x.imagesUrl.map((y, i) => {
          return <img key={`image-${i}`} className="p-3 max-h-64 max-w-full object-contain" loading="lazy" src={y.url} alt="" />;
        })}
      </div>
    );
  };

  return (
    <>
      <Title>News</Title>
      <Loader isLoading={isLoading} />

      <div className="lg:grid lg:grid-cols-12 gap-3">
        <div className="col-span-12">
          <FriendlyFilterCard
            filters={filters}
            setFilters={setFilters}
            setQueryString={setQueryString}
            searchUri={NewsBackendPath}
            fieldsUri={NewsBackendPath + FieldValuesBackendPostfix}
            onClick={hackRequest}
            metadata={metadata}
          />
        </div>

        <InvisibleScroll ref={gameMultiSelectRef}/>

        <div className="col-span-12">
          <div className="flex items-center justify-end">{paginationWrapper(false, true)}</div>

          {payload &&
            payload.entries &&
            payload.entries.map((x, i) => {
              return (
                <Card key={`news-${i}`} className="m-2" title={x.title}>
                  <div className="flex items-center justify-center italic text-sm">
                    <div className="m-1">Published on:</div>
                    <Time time={x.createdDate} />
                  </div>
                  {images(x)}
                  <Markdown className={"text-contrastChangeText text-left break-words"}>{x.text}</Markdown>

                  <div className="grid grid-cols-12 gap-1 w-full">
                    <div className="col-span-7">{relatedUrl(x)}</div>
                    <div className="col-span-5">{<Tags payload={x} />}</div>
                  </div>

                  <div className="flex items-center justify-end">{content(x)}</div>
                </Card>
              );
            })}
          <div className="flex items-center justify-end">{paginationWrapper(true, false)}</div>
        </div>
      </div>
    </>
  );
}
