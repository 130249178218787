import React, { useState } from "react";
import PropTypes from "prop-types";
import Button from "components/button";
import Heading from "components/heading";
import { Table } from "components/table";

/* CUSTOMS */
import CreditItem from "./creditItem";

/* ICON */
import { FaPlus as AddFilterIcon } from "react-icons/fa";
import Card from "components/card/index";


export default function CreditsGrid({ creditList, setCreditList }) {

  return (
    <>
      <Card className="m-8" title="Credits">

        {creditList && creditList.map((_, i) => {
          return (
            <div key={`Tbody-credit-${i}`}>
              <Heading className="text-left">Entry #{i + 1}</Heading>
              <CreditItem creditList={creditList} setCreditList={setCreditList} index={i} />
            </div>
          );
        })}

        <div className="flex justify-end">
          <Button
            className={"col-span-2 mr-1 ml-3 mb-3 mt-3 overflow-hidden rounded-b-md rounded-t-md"}
            onClick={() => {
              const temp = [...creditList];
              temp.push({});
              setCreditList(temp);

            }}
          >
            <AddFilterIcon />
          </Button>
        </div>
      </Card>
    </>
  );
}


CreditsGrid.propTypes = {
  creditList: PropTypes.array.isRequired,
  setCreditList: PropTypes.func.isRequired,
};
