/*
    Provides a base class for common functionality 
*/

// @ts-check

class Platform {
  /**
   * @param {Uint8Array} rom
   **/
  constructor(rom) {
    this.rom = rom;
  }

  /** @returns {boolean} */
  hasExternalHeader() {
    return false;
  }

  /**
   * @returns {number}
   */
  externalHeaderLength() {
    return 0;
  }

  /**
   * Converts the rom to a normalized form.
   * @returns {object} Information about the conversion
   */
  normalize() {
    return { message: "", rom: null };
  }
}

export default Platform;
