/* LIBS */
import React, { forwardRef, useImperativeHandle, useRef } from "react";
import PropTypes from "prop-types";

/* CUSTOMS */
import { RandomId } from "lib/randomId";

const InvisibleScroll = forwardRef(({ targetId }, ref) => {
  const targetRef = useRef(null);

  if (!targetId) {
    targetId = RandomId();
  }

  useImperativeHandle(ref, () => ({
    createUrlString: () => `#${targetId}`,
    jumpToSection: () => {
      if (targetRef.current) {
        targetRef.current.scrollIntoView({ behavior: "smooth" });
      }
    },
  }));

  return (
    <div id={targetId} ref={targetRef} className="invisible"/>
  );
});

InvisibleScroll.displayName = "InvisibleScroll";

InvisibleScroll.propTypes = {
  targetId: PropTypes.string,
};

export default InvisibleScroll;
