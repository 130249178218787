import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";

const SideMenu = ({ isOpen, toggleMenu, className, children }) => {
  const menuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        toggleMenu();
      }
    };

    if (isOpen) {
      document.body.style.overflow = "hidden"; // Prevent scrolling
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.body.style.overflow = ""; // Restore scrolling
    };
  }, [isOpen, toggleMenu]);

  return (
    <div
      style={{ zIndex: 9999 }}
      className={`left-0  fixed inset-0 bg-gray-900 
        bg-opacity-50 transition-opacity duration-500 ${isOpen ? "opacity-100" : "opacity-0 pointer-events-none"}`}
    >
      <div
        ref={menuRef}
        className={`${className} w-64 h-full text-white transition-transform duration-500 transform ${
          isOpen ? "translate-x-0" : "-translate-x-full"
        }`}
      >
        {children}
      </div>
    </div>
  );
};

// PropTypes for SideMenu component
SideMenu.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggleMenu: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default SideMenu;
