
import React from "react";
import PropTypes from "prop-types";
import "./table.css";

export const Table = ({children}) => (
  <table className="Table">{children}</table>
);

Table.propTypes = {
  children: PropTypes.any,
};
  