/* LIBS */
import React from "react";
import PropTypes from "prop-types";

/* CUSTOMS */
import Card from "components/card";

export default function DeletionNoticeCard({ payload }) {
  if (!payload) {
    return;
  }

  if (!payload.isDeleted) {
    return;
  }

  const text =
    "Hello traveler, unfortunately, you've hit a dead end. " +
    "This database entry has been deleted, media and all. Please try another castle!";

  return <Card title={payload.title || payload.username}>{text}</Card>;
}

DeletionNoticeCard.propTypes = {
  payload: PropTypes.shape({
    title: PropTypes.string,
    username: PropTypes.string,
    isDeleted: PropTypes.bool.isRequired,
  }),
};