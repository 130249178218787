/* LIBS */
import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import Moment from "react-moment";

/* CUSTOMS */
import { EpochSecondsToEpochMilliseconds } from "lib/time";

// Maximum valid duration for timer
const MAX_DURATION = 99 * 60 * 60 + 59 * 60 + 59;

export default function Timer({ startTime }) {
  const [timer, setTimer] = useState("");
  const intervalRef = useRef(null);

  useEffect(() => {
    // Clear any existing timer when startTime changes
    clearTimer();

    if (startTime) {
      startTimer(startTime);
    }

    // Cleanup on component unmount
    return clearTimer;
  }, [startTime]);

  const startTimer = (startTime) => {
    let currentTime = startTime;

    const updateTimer = () => {
      setTimer(PrintAgo(currentTime));
      currentTime += 1; // Increment by one second
    };

    intervalRef.current = setInterval(updateTimer, 1000);
    updateTimer(); // Initial update
  };

  const clearTimer = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
    setTimer(""); // Reset timer display
  };

  return <>{timer}</>;
}

export function PrintAgo(time) {
  const elapsedTime = new Date() - EpochSecondsToEpochMilliseconds(time);

  return <>{time < MAX_DURATION ? <Moment date={elapsedTime} format="hh:mm:ss" durationFromNow /> : "N/A"}</>;
}

export function PrintTime({ seconds }) {
  if (!seconds || seconds >= MAX_DURATION) {
    return "N/A";
  }

  const s = Math.floor(seconds % 60);
  const m = Math.floor((seconds / 60) % 60);
  const h = Math.floor(seconds / 3600);

  return `${String(h).padStart(2, "0")}:${String(m).padStart(2, "0")}:${String(s).padStart(2, "0")}`;
}

Timer.propTypes = {
  startTime: PropTypes.number,
};
