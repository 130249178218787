import data from "./subforums.json";

export const flattenedData = () => {

  const result = {};

  for (const key in data) {
    for (const innerKey in data[key]) {
      const innerValue = data[key][innerKey];

      // If the innerValue is not already a key in the result, create it
      if (!result[innerKey]) {
        result[innerKey] = new Set(); // Use Set to avoid duplicates
      }

      // Add the inner value to the set for that innerKey
      result[innerKey].add(innerValue);
    }
  }

  // Convert Sets back to arrays (or keep as is if you want)
  for (const key in result) {
    result[key] = Array.from(result[key]);
  }

  return result;
};

export function extractTags() {
  const result = {};

  for (const key in data) {
    for (const innerKey in data[key]) {
      const innerObject = data[key][innerKey];

      result[innerObject.tag] = innerKey;
    }
  }

  return result;
}