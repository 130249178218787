/** LIBS */
import React from "react";
import { Route, Routes } from "react-router-dom";

/* CUSTOMS */
import SeoHeader from "components/seoHeader";
import Login from "./login";
import Registration from "./registration1";
import Logout from "./logout";

/* CONSTANTS */
import {
  RegistrationFrontendPath,
  UserFrontendPath,
  LoginFrontendPath,
  LogoutFrontendPath,
} from "constants/routing/frontend";
import Registration2 from "./registration2";

export default function SubmissionRoutes() {
  const hiddenLinks = [
    {
      text: "Login",
      path: UserFrontendPath + LoginFrontendPath,
      pathPart: LoginFrontendPath,
      element: <Login />,
    },
    {
      text: "Registration",
      path: UserFrontendPath+ RegistrationFrontendPath,
      pathPart: RegistrationFrontendPath,
      element: <Registration />,
    },
    {
      text: "Registration",
      path: UserFrontendPath + RegistrationFrontendPath,
      pathPart: RegistrationFrontendPath + "/:jwtToken",
      element: <Registration2 />,
    },
    {
      text: "Logout",
      path: UserFrontendPath+ LogoutFrontendPath,
      pathPart: LogoutFrontendPath,
      element: <Logout />,
    },
  ];

  return (
    <>
      <SeoHeader pageTitle={"User"} />

      <Routes>
        {hiddenLinks.map(({ pathPart, element }, i) => {
          return <Route key={`user-${i}`} path={pathPart} exact element={element} />;
        })}
      </Routes>
    </>
  );
}
