/** LIBS */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";

/** CUSTOMS */
import MultiSelect from "components/multiSelect";
import { ToLabelValueFromMap, ToLabelValue } from "lib/strings";
import { fetchPost } from "lib/fetch";
import { formatDbFieldString } from "lib/strings";

/* CONSTANTS */
import { filterOperatorMust, requestFormatFilter } from "lib/elastic";
import { autocompleteWindowMax } from "constants/models";

export default function FilterItem({ filterItem, index, filters, setFilters, searchUri, fieldsUri }) {
  const [value, setValue] = useState(filterItem ? filterItem.value : "");
  const [fieldValues, setFieldValues] = useState(null);
  const [autocompleteController, setController] = useState(null);
  const [valueAutocomplete, setValueAutocomplete] = useState("");

  const [firstLoad, setFirstLoad] = useState(true);

  useEffect(() => {
    if (!filterItem.field) {
      return;
    }

    if (firstLoad){
      setFirstLoad(false);
      return;
    }

    fieldGet();
  }, [valueAutocomplete]);

  
  useEffect(() => {
    setFieldValues(null);
  }, [filters]);

  useEffect(() => {
    if (filters.length === 0 || index === null) {
      return;
    }

    var unchanged = false;

    const temp = [...filters];

    if (value) {
      temp[index] = {
        field: filterItem.field,
        operator: filterOperatorMust,
        value: value,
      };
    } else {
      /*
      Don't update the hook if already null
      This prevents unnecessary useEffect calls
      */
      if(!temp[index]){
        unchanged = true;
      }

      temp[index] = null;
    }

    setValueAutocomplete("");

    if (!unchanged){
      setFilters(temp);
    }
  }, [value]);

  const fieldGet = () => {
    // If an entry is selected, remove the filter to allow the dropdown to display other options.
    
    let temp = value ? "" : valueAutocomplete;

    var tempFilters = [...filters];
    tempFilters[index] = null;

    const params = {
      field: filterItem.field,
      value: temp,
      filters: requestFormatFilter(tempFilters),
    };

    if (autocompleteController) {
      autocompleteController.abort();
      setController(null);
    }

    const controller2 = new AbortController();
    setController(controller2);

    fetchPost(fieldsUri, params, controller2.signal)
      .then((resp) => {
        setFieldValues(resp);
      })
      .catch((e) => {
        toast.error(e.message);
      });
  };
  
  return (
    <MultiSelect
      placeholder={formatDbFieldString(filterItem.field)}
      isMulti={false}
      className="m-1 text-green-500"
      onMenuOpen={() => {
        if (fieldValues) {
          return;
        }

        fieldGet();
      }}
      onClear={() => {
        setValue(null);
        setValueAutocomplete("");
      }}
      value={ToLabelValue(value)}
      onSelect={(e) => {
        setValue(e.label);
      }}
      onInputChange={(value, meta) => {
        if (!setValueAutocomplete) {
          return;
        }

        if (value.length > valueAutocomplete.length && Object.keys(fieldValues).length < autocompleteWindowMax) {
          return;
        }

        if (value === "" && (meta.action === "set-value" || meta.action === "menu-close" || meta.action === "input-blur")) {
          return;
        }
        setValueAutocomplete(value);
      }}
      options={ToLabelValueFromMap(fieldValues)}
    />
  );
}

FilterItem.propTypes = {
  filterItem: PropTypes.object,
  action: PropTypes.string,
  index: PropTypes.number,
  searchUri: PropTypes.string.isRequired,
  fieldsUri: PropTypes.string.isRequired,

  filters: PropTypes.arrayOf(
    PropTypes.shape({
      operator: PropTypes.string,
      field: PropTypes.string.isRequired,
      value: PropTypes.string,
    })
  ).isRequired,
  setFilters: PropTypes.func,
};
